import { SVGProps } from "react";
import * as icon from "@icon";
import * as S from "./Icon.style";

export type IconNameType = keyof typeof icon;

export interface IconProps extends SVGProps<SVGSVGElement> {
  name: IconNameType;
  height?: string;
  width?: string;
  style?: React.CSSProperties;
}

const Icon = ({ name, style, width, height, ...rest }: IconProps) => {
  const SVGIcon = icon[name];

  return (
    <S.Icon
      as={SVGIcon}
      {...rest}
      style={{
        ...style,
        width: width ?? "auto",
        height: height ?? "auto",
      }}
    />
  );
};

export default Icon;
