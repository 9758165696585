import { ColorsType } from "@style/colorPalette";
import * as S from "./Badge.style";
import Text from "@atoms/Text/Text";

interface BadgeProps {
  title: string;
  color: ColorsType;
  top?: string;
  left?: string;
  right?: string;
  bottom?: string;
  size: "small" | "large";
}

const Badge = ({
  title,
  color,
  top,
  left,
  right,
  bottom,
  size,
}: BadgeProps) => {
  return (
    <S.BadgeContainer
      top={top}
      left={left}
      right={right}
      bottom={bottom}
      size={size}
    >
      <Text
        typography={size === "large" ? "t19" : "t12"}
        fontWeight={600}
        color={color}
      >
        {title}
      </Text>
    </S.BadgeContainer>
  );
};

export default Badge;
