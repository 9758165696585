import React from "react";
import { CSSProperties } from "react";

import * as S from "./Button.style";
import { TypographyType } from "@style/typography";
import { ButtonColorType } from "@style/button";

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  color?: ButtonColorType;
  weak?: boolean;
  full?: boolean;
  disabled?: boolean;
  typography?: TypographyType;
  onClick: () => void;
  borderRadius?: string;
  fontWeight?: CSSProperties["fontWeight"];
  width?: string;
  height?: string;
  hover?: boolean;
  children: React.ReactNode;
}

const Button = ({ children, ...props }: ButtonProps) => {
  return <S.Button {...props}>{children}</S.Button>;
};

export default Button;
