import { useState } from "react";
import Icon, { IconNameType } from "@atoms/Icon/Icon";

function Right({ disabled }: { disabled: boolean }) {
  const [iconRight, setIconRight] = useState<IconNameType>(
    "IconCircleArrowRight"
  );
  return (
    <Icon
      name={iconRight}
      width="3.9rem"
      height="3.9rem"
      onMouseEnter={() => {
        if (disabled) {
          return;
        } else {
          setIconRight("IconCircleArrowRightHover");
        }
      }}
      onMouseLeave={() => setIconRight("IconCircleArrowRight")}
    />
  );
}

export default Right;
