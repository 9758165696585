import styled from "@emotion/styled";

import { MEDIA_QUERIES } from "@constants/breakpoint";

export const SeardhKeywordLayout = styled.main`
  width: 100%;
  position: relative;
`;

export const FindTeacherContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: "relative";
  max-width: 900px;
  margin: 0 auto;

  @media ${MEDIA_QUERIES.MOBILE} {
    max-width: 250px;
  }
  @media ${MEDIA_QUERIES.TABLET} {
    max-width: 480px;
  }
  @media ${MEDIA_QUERIES.LAPTOP} {
    max-width: 750px;
  }
`;
