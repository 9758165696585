import { useState } from "react";
import { Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";

import { MAIN_BANNER } from "@constants/banner";
import * as S from "./EventBanner.style";
import Flex from "@atoms/Flex/Flex";
import Image from "@atoms/Image/Image";

const EventBanner = () => {
  const [, setSwiper] = useState<SwiperClass | null>(null);
  return (
    <S.EventBannerWrapper>
      <Swiper
        pagination={{
          type: "fraction",
        }}
        modules={[Pagination, Autoplay]}
        onSwiper={(swiper: SwiperClass) => setSwiper(swiper)}
        loop={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        {MAIN_BANNER.map((banner, i) => (
          <SwiperSlide key={i}>
            <Flex
              style={{
                width: "100%",
                height: "100%",
              }}
            >
              <Image
                width="100%"
                src={banner.img}
                alt={`banner-${i}`}
                objectFit="cover"
                height="100%"
              />
            </Flex>
          </SwiperSlide>
        ))}
      </Swiper>
    </S.EventBannerWrapper>
  );
};

export default EventBanner;
