export const EMAIL_PATTERN = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const PASSWORD_PATTERN =
  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;

export const BIRTH_PATTERN =
  /^(19|20)\d{2}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/;

export const NICKNAME_PATTERN = /^[a-zA-Z0-9가-힣]{2,10}$/;

export const NO_WHITESPACE_PATTERN = /^\S+$/;
