import styled from "@emotion/styled";
import { FlexProps } from "@atoms/Flex/Flex";

export const Flex = styled.div<FlexProps>(
  ({ align, justify, direction, full, gap }) => ({
    display: "flex",
    gap: `${gap}rem`,
    alignItems: align,
    justifyContent: justify,
    flexDirection: direction,
    width: full ? "100%" : "auto",
  })
);
