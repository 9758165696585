import { Navigate, Route, Routes } from "react-router-dom";

import MainPage from "@pages/Main";

import Layout from "@templates/Layout/Layout";
import SignupPage from "@pages/Signup";
import FindIdResultPage from "@pages/FindIdResult";
// import FilterPage from "@pages/Filter";
import SearchResultPage from "@pages/SearchResult";
import SigninPage from "@pages/Signin";
import FindIdPwPage from "@pages/FindIdPw";
import ResetPwPage from "@pages/ResetPw";

// import MainPage from "@pages/Main";
// import SigninPage from "@pages/Signin";
// import HeaderLayout from "@/components/shared/HeaderLayout";
// import SearchResultPage from "@pages/SearchResult";
// import FilterPage from "@pages/Filter";
// import FilterResultPage from "@pages/FilterResult";
// import SignupPage from "@pages/Signup";
// import SimpleLayout from "@/components/shared/SimpleLayout";
// import FindIdPwPage from "@pages/FindIdPw";
// import FindIdResultPage from "@pages/FindIdResult";
// import ResetPwPage from "@pages/ResetPw";

const Router = () => {
  // const token = useRecoilValue(tokenAtom);
  // const navigate = useNavigate();

  // useEffect(() => {
  //   if (!token) {
  //     navigate("/login");
  //     // navigate("/");
  //   } else {
  //     navigate("/");
  //     // navigate("/login");
  //   }
  // }, [token, navigate]);
  return (
    <Routes>
      <Route element={<Layout hasHeader />}>
        <Route path="/login" Component={SigninPage} />
        <Route path="/" Component={MainPage} />
        <Route path="/search" Component={SearchResultPage} />
        {/* <Route path="/filter" Component={FilterPage} /> */}
        {/* <Route path="/filter/result" Component={FilterResultPage} /> */}
      </Route>

      <Route element={<Layout />}>
        <Route path="/signUp" Component={SignupPage} />
        <Route path="/find" Component={FindIdPwPage} />
        <Route path="/find/result" Component={FindIdResultPage} />
        <Route path="/reset" Component={ResetPwPage} />
      </Route>

      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default Router;
