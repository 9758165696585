import * as S from "./TeacherCardImage.style";
import { DeviceType, TeacherCardProps } from "@models";
import Text from "@atoms/Text/Text";
import Image from "@atoms/Image/Image";
import Badge from "@molecules/Badge/Badge";
import Heart from "@molecules/Icon/Heart";
import TeacherInfo from "@molecules/Card/TeacherCardInfo";
import useDeviceType from "@hooks/useDeviceType";
import { returnColor } from "@utils";

interface TeacherCardInterface {
  teacher: TeacherCardProps;
  size: "small" | "large";
}

const TeacherCard = ({ teacher, size }: TeacherCardInterface) => {
  const { deviceType } = useDeviceType();
  const clickHeart = () => {
    console.log("좋아요!");
  };

  const getImageHeight = (
    deviceType: DeviceType,
    size: "large" | "small"
  ): string => {
    if (deviceType === "mobile") {
      return size === "large" ? "161px" : "160px";
    }
    if (deviceType === "tablet") {
      return size === "large" ? "201px" : "240px";
    }
    if (deviceType === "laptop") {
      return size === "large" ? "271px" : "205px";
    }
    return size === "large" ? "341px" : "205px";
  };

  return (
    <S.TeacherCardContainer size={size}>
      {teacher.status !== "상담가능" && (
        <S.TeacherBlackBg size={size}>
          <Text
            typography={size === "large" ? "t24" : "t16"}
            color="white100"
            fontWeight={600}
          >
            {teacher.status}
          </Text>
        </S.TeacherBlackBg>
      )}
      <Image
        src={teacher.img}
        alt={`${teacher.id}-teacher`}
        width="100%"
        objectFit="cover"
        height={getImageHeight(deviceType, size)}
      />
      <Badge
        title={teacher.kind}
        color={returnColor(teacher.kind)}
        size={size}
        top="2.5rem"
        left="2.2rem"
      />
      <Heart
        like={teacher.like}
        onClick={clickHeart}
        top="1.4rem"
        right="1rem"
        size={size}
      />

      <TeacherInfo size={size} teacher={teacher} />
    </S.TeacherCardContainer>
  );
};

export default TeacherCard;
