export const MOBILE_MAX_WIDTH = 480; // 모바일 최대값
export const TABLET_MIN_WIDTH = 481; // 태블릿 최소값
export const TABLET_MAX_WIDTH = 768; // 태블릿 최대값
export const LAPTOP_MIN_WIDTH = 769; // 랩탑 최소값
export const LAPTOP_MAX_WIDTH = 1440; // 랩탑 최대값
export const DESKTOP_MIN_WIDTH = 1441; // 데스크탑 최소값

export const MEDIA_QUERIES = {
  MOBILE: `screen and (max-width: ${MOBILE_MAX_WIDTH}px)`,
  TABLET: `screen and (min-width: ${TABLET_MIN_WIDTH}px) and (max-width: ${TABLET_MAX_WIDTH}px)`,
  LAPTOP: `screen and (min-width: ${LAPTOP_MIN_WIDTH}px) and (max-width: ${LAPTOP_MAX_WIDTH}px)`,
  DESKTOP: `screen and (min-width: ${DESKTOP_MIN_WIDTH}px)`,
};
