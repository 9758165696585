import styled from "@emotion/styled";

import { MEDIA_QUERIES } from "@constants/breakpoint";
import { colors } from "@style/colorPalette";

export const CategoryBox = styled.div<{ size: "small" | "large" }>`
  /* padding: 4px 12px; */
  padding: ${({ size }) =>
    size === "large" ? "0.4rem 1.2rem" : "0.2rem 0.6rem"};
  border-radius: 0.8rem;
  background-color: ${colors.grey93};

  @media ${MEDIA_QUERIES.MOBILE} {
    padding: ${({ size }) => (size === "large" ? "0.4rem 0.8rem" : "2px 6px")};
  }
  @media ${MEDIA_QUERIES.TABLET} {
    padding: ${({ size }) => (size === "large" ? "0.4rem 0.9rem" : "2px 6px")};
  }
  @media ${MEDIA_QUERIES.LAPTOP} {
    padding: ${({ size }) => (size === "large" ? "0.4rem 1rem" : "2px 6px")};
  }
`;
