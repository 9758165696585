import { colors, ColorsType } from "@style/colorPalette";
import { CSSProperties } from "react";

import { TypographyType } from "@style/typography";
import * as S from "./CustomCheckBox.style";
import Flex from "@atoms/Flex/Flex";
import Icon from "@atoms/Icon/Icon";
import Text from "@atoms/Text/Text";
import { GenderType } from "@models";

type SelectType = string | number | GenderType;

interface CustomCheckBoxProps {
  isSelected: boolean;
  onClick: (selectedId: SelectType) => void;
  value: string;
  width?: string;
  height?: string;
  index?: number;
  borderRadius?: string;
  padding?: string;
  hasBorder?: boolean;
  color?: ColorsType;
  typography?: TypographyType;
  gap?: CSSProperties["gap"];
  checkBoxSize?: number;
}

const CustomCheckBox = ({
  isSelected,
  onClick,
  value,
  height,
  width,
  index,
  borderRadius,
  padding,
  hasBorder = true,
  color = "main",
  typography = "t22",
  gap = 2.1,
  checkBoxSize = 3.1,
}: CustomCheckBoxProps) => {
  return (
    <S.CustomCheckBoxContainer
      width={width}
      height={height}
      onClick={() => onClick(index || value)}
      borderRadius={borderRadius || "1.5rem"}
      padding={padding || "2.3rem 2.4rem"}
      hasBorder={hasBorder}
    >
      <Flex
        gap={gap}
        align="center"
        style={{
          flex: 1,
        }}
      >
        <Flex
          justify="center"
          align="center"
          style={{
            width: `${checkBoxSize}rem`,
            height: `${checkBoxSize}rem`,
            borderRadius: "0.4rem",
            boxSizing: "border-box",
            border: isSelected ? "none" : `1px solid ${colors.grey56}`,

            background: isSelected ? colors.red67 : colors.white100,
          }}
        >
          {isSelected && (
            <Icon
              name="IconStyleCheck"
              width={`${checkBoxSize - 0.5}rem`}
              height={`${checkBoxSize - 0.5}rem`}
            />
          )}
        </Flex>
        <Text typography={typography} color={color}>
          {value}
        </Text>
      </Flex>
    </S.CustomCheckBoxContainer>
  );
};

export default CustomCheckBox;
