import styled from "@emotion/styled";

import { TextProps } from "@atoms/Text/Text";
import { colors } from "@style/colorPalette";
import { typographyMap } from "@style/typography";

export const Text = styled.span<TextProps>(
  ({ color = "black", display, textAlign, fontWeight, bold, lineHeight }) => ({
    color: colors[color], // var(--red)
    textAlign,
    fontWeight: bold ? "bold" : fontWeight,
    display,
    lineHeight,
    width: "auto",
    whiteSpace: "nowrap",
  }),
  ({ typography = "t16" }) => typographyMap[typography]
);
