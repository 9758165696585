import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import useDeviceType from "@hooks/useDeviceType";
import { useQueryParams } from "@hooks/useQueryParams";
import Flex from "@atoms/Flex/Flex";
import Icon from "@atoms/Icon/Icon";
import TextArrowGroup from "@molecules/Group/TextArrowGroup";
import * as S from "./Header.style";
import HeaderMenu from "@molecules/Navigation/HeaderMenu";
import Image from "@atoms/Image/Image";
import Logo from "@img/logo.png";
import Text from "@atoms/Text/Text";

interface HeaderProps {
  toggleMenu: () => void;
  toggleSearch: () => void;
}

export const Header = ({ toggleMenu, toggleSearch }: HeaderProps) => {
  const { deviceType } = useDeviceType();
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");

  const { query } = useQueryParams();

  const onChange = (value: string) => {
    setSearchValue(value);
  };
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // console.log(searchValue, "보내짐");
    if (searchValue === "") {
      toast.info("검색어를 입력해주세요.");
    } else {
      navigate(`/search?query=${encodeURIComponent(searchValue)}`);
    }
  };
  const clickIcon = () => {
    navigate(`/search?query=${encodeURIComponent(searchValue)}`);
  };

  useEffect(() => {
    if (query) {
      setSearchValue(query);
    } else {
      setSearchValue("");
    }
  }, [query]);
  return (
    <S.HeaderContainer>
      <Flex
        align="center"
        justify="space-between"
        style={{
          height: "100%",
        }}
      >
        <Flex onClick={() => navigate("/")} style={{}}>
          <Image
            src={Logo}
            alt=""
            width="10rem"
            height="100%"
            style={{
              cursor: "pointer",
              height: "100%",
              marginRight: "2rem",
            }}
          />
        </Flex>
        <Text
          typography="t14"
          style={{
            position: "absolute",
          }}
        >
          {deviceType}
        </Text>

        {deviceType !== "laptop" && deviceType !== "desktop" && (
          <S.Hamburger>
            <li onClick={toggleMenu}>
              <Icon name="IconHamburger" width="2.2rem" height="2.2rem" />
            </li>
            <li>카테</li>
            <li>코인</li>
            <li onClick={() => navigate("/login")}>마이</li>
            <li onClick={toggleSearch}>검색</li>
          </S.Hamburger>
        )}

        {deviceType === "mobile" || deviceType === "tablet" ? null : (
          <>
            <Flex
              align="center"
              justify="space-between"
              style={{
                flex: 1,

                padding: deviceType === "laptop" ? "0 4rem" : "0 6rem",
              }}
            >
              <TextArrowGroup
                title="타로"
                iconName="IconHeaderCategoryRightArrow"
                iconWidth="1.15rem"
                iconHeight="0.75rem"
                typography="t24"
              />

              <TextArrowGroup
                title="신점"
                iconName="IconHeaderCategoryRightArrow"
                iconWidth="1.15rem"
                iconHeight="0.75rem"
                typography="t24"
              />

              <TextArrowGroup
                title="사주"
                iconName="IconHeaderCategoryRightArrow"
                iconWidth="1.15rem"
                iconHeight="0.75rem"
                typography="t24"
              />
            </Flex>

            <HeaderMenu
              onChange={onChange}
              onSubmit={onSubmit}
              clickIcon={clickIcon}
              inputValue={searchValue}
            />
          </>
        )}
      </Flex>
    </S.HeaderContainer>
  );
};
