import React, { ChangeEventHandler, forwardRef } from "react";
import * as S from "./Input.style";

import { ColorsType } from "@style/colorPalette";
import { TypographyType } from "@style/typography";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  id?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  borderRadius?: string;
  height?: string;
  typography?: TypographyType;
  placeholderColor?: string;
  placeholderFontSize?: TypographyType;
  color?: ColorsType;
  name?: string;
  padding?: string;
}

const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  return <S.Input {...props} ref={ref} />;
});

export default Input;
