import { useMediaQuery } from "react-responsive";
import { MEDIA_QUERIES } from "@constants/breakpoint";
import { DeviceType } from "@/models";

const useDeviceType = () => {
  const isMobile = useMediaQuery({ query: MEDIA_QUERIES.MOBILE });
  const isTablet = useMediaQuery({ query: MEDIA_QUERIES.TABLET });
  const isLaptop = useMediaQuery({ query: MEDIA_QUERIES.LAPTOP });
  const isDesktop = useMediaQuery({ query: MEDIA_QUERIES.DESKTOP });

  const deviceType: DeviceType = isMobile
    ? "mobile"
    : isTablet
    ? "tablet"
    : isLaptop
    ? "laptop"
    : "desktop";

  return { isMobile, isTablet, isLaptop, isDesktop, deviceType };
};

export default useDeviceType;
