import { termValue } from "@models";

export const TERMS: termValue[] = [
  {
    id: 0,
    title: "[필수] 만 14세 이상입니다.",
  },
  {
    id: 1,
    title: "[필수] 만월타로신점 이용약관",
  },
  {
    id: 2,
    title: "[필수] 개인정보 수집 및 이용 동의",
  },
  {
    id: 3,
    title: "[선택] 광고성 정보 수신 동의",
  },
];
