import styled from "@emotion/styled";

export const NavigationWrapper = styled.div<{
  disabled: boolean;
  navigationAbsolute: boolean;
  direction: "left" | "right";
}>`
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: ${({ navigationAbsolute }) =>
    navigationAbsolute ? "absolute" : "static"};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  z-index: 2;
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};

  ${({ navigationAbsolute, direction }) =>
    navigationAbsolute &&
    direction === "left" &&
    `
    top: 50%;
    left: 2rem;
    transform: translateY(-50%);
    z-index:3;
  `}

  ${({ navigationAbsolute, direction }) =>
    navigationAbsolute &&
    direction === "right" &&
    `
    top: 50%;
    right: 2rem;
    transform: translateY(-50%);
    z-index:3;
  `}
`;
