import Icon from "@atoms/Icon/Icon";
import * as S from "./SearchDropDown.style";
import Text from "@atoms/Text/Text";

interface SearhDropDownProps {
  data: string[];
  clickActive: (filter: string) => void;
  activeFilter: string;
}

function SearchDropDown({
  data,
  clickActive,
  activeFilter,
}: SearhDropDownProps) {
  return (
    <S.SearchDropDownContainer>
      {data.map((filter, i) => (
        <li
          className="item"
          key={`${filter}-${i}`}
          onClick={() => clickActive(filter)}
        >
          <Icon
            name="IconDropCheck"
            width="2.2rem"
            height="2.2rem"
            style={{
              opacity: activeFilter === filter ? 1 : 0,
            }}
          />
          <Text
            typography="t18"
            color={activeFilter === filter ? "black33" : "grey56"}
          >
            {filter}
          </Text>
        </li>
      ))}
    </S.SearchDropDownContainer>
  );
}

export default SearchDropDown;
