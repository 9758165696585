import styled from "@emotion/styled";

import { colors } from "@style/colorPalette";

export const SwiperContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 3rem;
  position: relative;

  .custom-pagenation {
    width: 100%;
    position: absolute;
    bottom: 0;
    z-index: 4;
    display: flex;
    justify-content: center;
    gap: 1.4rem;

    .circle {
      width: 1.2rem;
      height: 1.2rem;
      border-radius: 50%;
      background-color: ${colors.grey77};
    }
    .circle-active {
      background-color: ${colors.red50};
    }
  }
`;
