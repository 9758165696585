import Flex from "@/components/atoms/Flex/Flex";
import { MEDIA_QUERIES } from "@/constants/breakpoint";
import styled from "@emotion/styled";

export const TeacherInfoWrapper = styled(Flex)<{ size: "small" | "large" }>`
  /* padding: 10px 10px 16px 24px; */
  padding: ${({ size }) =>
    size === "large" ? "1rem 1.5rem" : "0.4rem 1.1rem"};
  /* padding: ${({ size }) => (size === "large" ? "0 1.5rem" : "0 1.1rem")}; */
  position: relative;
  height: calc(100% - 341px - 1rem * 2);

  @media ${MEDIA_QUERIES.MOBILE} {
    height: calc(100% - 161px - 1rem * 2);
  }
  @media ${MEDIA_QUERIES.TABLET} {
    height: calc(100% - 201px - 1rem * 2);
  }
  @media ${MEDIA_QUERIES.LAPTOP} {
    height: calc(100% - 271px - 1rem * 2);
  }
`;
