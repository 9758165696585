import { CSSProperties } from "react";
import * as S from "./Image.style";

export interface ImageProps extends React.HTMLAttributes<HTMLImageElement> {
  alt: string;
  src: string;
  width?: string;
  height?: string;
  onClick?: () => void;
  borderRadius?: string;
  objectFit?: CSSProperties["objectFit"];
}

const Image = ({
  alt,
  src,
  width,
  height,
  onClick,
  borderRadius,
  objectFit,
}: ImageProps) => {
  return (
    <S.Image
      alt={alt}
      src={src}
      width={width}
      height={height}
      borderRadius={borderRadius}
      onClick={onClick}
      objectFit={objectFit}
    />
  );
};

export default Image;
