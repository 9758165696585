import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import * as S from "./SignupLayout.style";
import { SignupValue } from "@models";
import ApplyForm from "@organisms/Form/Sign/ApplyForm";
import SignupForm from "@organisms/Form/Sign/SignupForm";
import NiceIdForm from "@organisms/Form/Sign/NiceIdForm";

const SignupLayout = () => {
  const navigate = useNavigate();
  const onSubmit = (data: SignupValue) => {
    console.log(data);
  };

  const [step, setStep] = useState(0);

  const handleNextStep = () => {
    if (step === 2) {
      alert("이제 본인인증 창 떠요");
      navigate("/");
    } else {
      setStep(step + 1);
    }
  };

  const handlePrevStep = () => {
    if (step === 0) {
      navigate(-1);
    } else {
      setStep(step - 1);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  const steps: Record<number, JSX.Element> = {
    0: <NiceIdForm prevStep={handlePrevStep} nextStep={handleNextStep} />,
    1: <ApplyForm nextStep={handleNextStep} prevStep={handlePrevStep} />,
    2: (
      <SignupForm
        onSubmit={onSubmit}
        nextStep={handleNextStep}
        prevStep={handlePrevStep}
      />
    ),
    // 2: <NiceIdForm prevStep={handlePrevStep} />,
  };
  return (
    <S.Bg>
      <S.ProgressWrapper>
        <S.ProgressBar width={`${(step / 3) * 100}%`} />
      </S.ProgressWrapper>
      {steps[step]}
    </S.Bg>
  );
};

export default SignupLayout;
