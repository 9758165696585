import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useForm } from "react-hook-form";

import {
  EMAIL_PATTERN,
  NO_WHITESPACE_PATTERN,
  PASSWORD_PATTERN,
} from "@constants/regExp";
import { LoginValue } from "@models";
import { useSignIn } from "@hooks/useSignIn";

import * as S from "./SigninForm.style";
import Spacer from "@atoms/Spacer/Spacer";
import Image from "@atoms/Image/Image";
import Logo from "@img/logo.png";
import Flex from "@atoms/Flex/Flex";
import Text from "@atoms/Text/Text";
import Icon from "@atoms/Icon/Icon";
import Division from "@atoms/Division/Division";
import { Label } from "@atoms/Label/Label.style";
import Input from "@atoms/Input/Input";
import CustomCheckBox from "@molecules/Form/CustomCheckBox";

const SigninForm = ({
  onSubmit,
}: {
  onSubmit: (formValue: LoginValue) => void;
}) => {
  const navigate = useNavigate();
  const { loginNaver, loginApple, loginKakao, loginGoogle } = useSignIn();

  const [visible, setVisible] = useState(false);
  const toggleVisible = () => {
    setVisible(!visible);
  };

  const [maintain, setMaintain] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginValue>();

  return (
    <S.FormContainer onSubmit={handleSubmit(onSubmit)}>
      {/* <Spacer space="20.3rem" /> */}

      <Image src={Logo} alt="logo" width="39rem" height="33rem" />
      <Spacer space="3.5rem" />

      <Flex
        gap={2.4}
        direction="column"
        align="center"
        style={{
          width: "100%",
        }}
      >
        <S.SocialLoginBtn color="green40" onClick={loginNaver}>
          <Text typography="t28" color="white100">
            네이버로 계속하기
          </Text>
          <Icon name="IconNaver" width="3.1rem" height="2.9rem" />
        </S.SocialLoginBtn>
        <S.SocialLoginBtn color="black3">
          <Text typography="t28" color="white100" onClick={loginApple}>
            Apple로 계속하기
          </Text>
          <Icon name="IconApple" width="3.6rem" height="4.5rem" />
        </S.SocialLoginBtn>
        <S.SocialLoginBtn color="yellow50" onClick={loginKakao}>
          <Text typography="t28" color="black">
            카카오로 계속하기
          </Text>
          <Icon name="IconKakao" width="3.6rem" height="3.6rem" />
        </S.SocialLoginBtn>
        <S.SocialLoginBtn color="white100" onClick={loginGoogle}>
          <Text typography="t28" color="grey53">
            Google로 계속하기
          </Text>
          <Icon name="IconGoogle" width="3rem" height="3rem" />
        </S.SocialLoginBtn>
      </Flex>

      <Spacer space="4.9rem" />
      <Division />
      <Spacer space="3rem" />

      <Flex full direction="column">
        <Flex direction="column">
          <Flex direction="column">
            <Label color="black33" typography="t22" htmlFor="email">
              ID(이메일)
            </Label>
            <Spacer space="1.2rem" />
            <Input
              id="email"
              autoComplete="off"
              typography="t20"
              placeholder="아이디를 입력해주세요"
              placeholderFontSize="t20"
              {...register("email", {
                required: "이메일을 입력해주세요.",
                validate: {
                  noWhitespace: (value) =>
                    NO_WHITESPACE_PATTERN.test(value) ||
                    "공백은 사용할 수 없습니다.",
                  isValidPattern: (value) =>
                    NO_WHITESPACE_PATTERN.test(value)
                      ? EMAIL_PATTERN.test(value) ||
                        "올바른 이메일 형식이 아닙니다."
                      : true,
                },
              })}
            />
            <Spacer space="0.9rem" />
            {errors?.email && (
              <Text typography="t12" color="red67" fontWeight={200}>
                {errors?.email.message}
              </Text>
            )}
          </Flex>
          <Spacer space="3rem" />

          <Flex
            direction="column"
            style={{
              position: "relative",
            }}
          >
            <Label color="black33" typography="t22" htmlFor="password">
              비밀번호
            </Label>
            <Spacer space="1.2rem" />
            <Flex
              style={{
                position: "relative",
              }}
            >
              <Input
                id="password"
                autoComplete="off"
                type={visible ? "text" : "password"}
                typography="t20"
                padding="0 4rem 0 1.4rem"
                placeholderFontSize="t20"
                placeholder="비밀번호를 입력해주세요."
                {...register("password", {
                  required: "비밀번호를 입력해주세요.",
                  validate: {
                    noWhitespace: (value) =>
                      NO_WHITESPACE_PATTERN.test(value) ||
                      "공백은 사용할 수 없습니다.",
                    isValidPattern: (value) =>
                      NO_WHITESPACE_PATTERN.test(value)
                        ? PASSWORD_PATTERN.test(value) ||
                          "비밀번호는 6자 이상(영문, 숫자, 특수문자 포함)이어야 합니다."
                        : true,
                  },
                })}
              />

              <Icon
                name="IconInVible"
                onClick={toggleVisible}
                width="2.4rem"
                height="2.4rem"
                style={{
                  position: "absolute",
                  right: "1.5rem",
                  top: "50%",
                  cursor: "pointer",
                  transform: "translateY(-50%)",
                }}
              />
            </Flex>

            <Spacer space="0.9rem" />

            {errors?.password && (
              <Text typography="t12" color="red67" fontWeight={200}>
                {errors?.password.message}
              </Text>
            )}
          </Flex>
          <Spacer space="3rem" />
        </Flex>
      </Flex>

      <Flex justify="space-between" align="center" full>
        <Flex align="cener">
          <CustomCheckBox
            value="로그인 유지"
            isSelected={maintain}
            onClick={() => setMaintain(!maintain)}
            width="100%"
            height="auto"
            padding="0"
            typography="t14"
            color="grey53"
            gap={1}
            hasBorder={false}
            checkBoxSize={2}
          />
        </Flex>

        <Text
          typography="t14"
          fontWeight={300}
          color="grey53"
          style={{
            cursor: "pointer",
          }}
          onClick={() => navigate("/find")}
        >
          아이디/비번찾기
        </Text>
      </Flex>
      <Spacer space="3rem" />
      <Division />
      <Spacer space="1.4rem" />

      <Text
        typography="t24"
        color="black33"
        onClick={() => navigate("/signup")}
        style={{
          cursor: "pointer",
        }}
      >
        이메일로 회원가입하기
      </Text>
      <Spacer space="3.7rem" />
      <Text
        typography="t24"
        color="grey69"
        style={{
          cursor: "pointer",
        }}
      >
        로그인이 안될시 문제해결
      </Text>

      <button type="submit" style={{ display: "none" }}>
        Submit
      </button>
    </S.FormContainer>
  );
};

export default SigninForm;
