import styled from "@emotion/styled";
import { SpacingProps } from "./Spacer";

export const Spacer = styled.div<SpacingProps>`
  ${({ space, direction = "vertical" }) =>
    direction === "vertical"
      ? `
        max-height: ${space};
        height: ${space};
      `
      : `
        max-width: ${space};
        width: ${space};
      `}
`;
