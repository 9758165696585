import { MEDIA_QUERIES } from "@/constants/breakpoint";
import { colors } from "@/style/colorPalette";
import styled from "@emotion/styled";

export const BestReviewContainer = styled.div`
  max-width: 1121px;
  margin: 0 auto;
  width: 100%;
  height: 57rem;
  border-radius: 2rem;
  background-color: ${colors.main};
  padding: 1.9rem 0px 3.1rem 2.2rem;
  box-sizing: border-box;

  @media ${MEDIA_QUERIES.MOBILE} {
    max-width: 300px;
  }
  @media ${MEDIA_QUERIES.TABLET} {
    max-width: 550px;
  }
  @media ${MEDIA_QUERIES.LAPTOP} {
    max-width: 900px;
  }

  .swiper {
    padding-right: 3.2rem;
    box-sizing: border-box;
  }

  .swiper-slide {
    width: auto;
  }
`;
