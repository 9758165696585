import { useState } from "react";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";

import TeacherSection from "./TeacherSection";
import Flex from "@atoms/Flex/Flex";
import Text from "@atoms/Text/Text";

import * as S from "./RecommendTeacherSection.style";
import Right from "@atoms/Navigation/Right";
import CustomNavigation from "@molecules/Navigation/CustomNavigation";
import Spacer from "@atoms/Spacer/Spacer";
import { TOP10TEACHER } from "@/data";
import TeacherSimpleCard from "@molecules/Card/TeacherSimpleCard";
import Left from "@atoms/Navigation/Left";
import useDeviceType from "@hooks/useDeviceType";

const TRANSITION_SPEED = 1000;

const RecommendTeacherSection = () => {
  const { deviceType } = useDeviceType();
  const [swiper, setSwiper] = useState<SwiperClass | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(0);

  const getPrevNumber = () => {
    if (deviceType === "mobile") return 2;
    if (deviceType === "tablet") return 4;
    return 5;
  };
  const SLIDES_TO_MOVE = getPrevNumber();

  const totalPages = Math.ceil(10 / SLIDES_TO_MOVE);

  const handleNext = () => {
    if (swiper) {
      const newIndex = Math.min(currentPage + 1, totalPages - 1);
      swiper.slideTo(newIndex * SLIDES_TO_MOVE, TRANSITION_SPEED);
      setCurrentPage(newIndex);
    }
  };

  const handlePrev = () => {
    if (swiper) {
      const newIndex = Math.max(currentPage - 1, 0);
      swiper.slideTo(newIndex * SLIDES_TO_MOVE, TRANSITION_SPEED);
      setCurrentPage(newIndex);
    }
  };

  return (
    <TeacherSection
      searchedAt="10.23 19:29"
      title={
        <Flex justify="center">
          <Text
            typography="t40"
            color="main"
            style={{
              marginRight: "10px",
            }}
          >
            인기 상담마스터 추천
          </Text>
        </Flex>
      }
    >
      <S.SwiperContainer>
        <CustomNavigation
          className="custom-prev"
          direction="left"
          navigationAbsolute={false}
          disabled={currentPage === 0 ? true : false}
          handleClick={handlePrev}
        >
          <Left disabled={currentPage === 0 ? true : false} />
        </CustomNavigation>

        <Spacer space="3.5rem" direction="horizontal" />

        <Swiper
          slidesPerView={getPrevNumber()}
          spaceBetween={13}
          onSwiper={(swiper: SwiperClass) => setSwiper(swiper)}
          modules={[Pagination, Navigation]}
          allowTouchMove={false}
        >
          {TOP10TEACHER.map((teacher) => (
            <SwiperSlide key={teacher.id}>
              <TeacherSimpleCard teacher={teacher} />
            </SwiperSlide>
          ))}
        </Swiper>

        <Spacer space="3.5rem" direction="horizontal" />

        <CustomNavigation
          direction="right"
          disabled={currentPage === totalPages - 1}
          handleClick={handleNext}
          navigationAbsolute={false}
        >
          {/* <Icon name="IconCircleArrowRight" /> */}
          <Right disabled={currentPage === totalPages - 1} />
        </CustomNavigation>

        <div className="custom-pagenation">
          {Array(totalPages)
            .fill("")
            .map((_, i) => (
              <div
                key={i}
                className={
                  i === currentPage ? "circle circle-active" : "circle"
                }
              ></div>
            ))}
        </div>
      </S.SwiperContainer>
    </TeacherSection>
  );
};

export default RecommendTeacherSection;
