import { useState } from "react";

import { ColorsType } from "@style/colorPalette";
import * as S from "./InputGroup.style";

import * as icon from "@icon";
import Input from "@atoms/Input/Input";
import Flex from "@atoms/Flex/Flex";
import Icon from "@atoms/Icon/Icon";
type IconNameType = keyof typeof icon;

interface InputGroupProps {
  onChange: (value: string) => void;
  inputValue: string;
  clickIcon: () => void;
  iconName: IconNameType;
  iconBoxWidth: string;
  iconBoxHeight: string;
  iconBoxBg: string;

  color?: ColorsType;
  padding?: string;
  width?: string;
  onSubmit?: (e: React.FormEvent<HTMLFormElement>) => void;
  placeHolder?: string;
  iconWidth?: string;
  iconHeight?: string;
}

const InputGroup = ({
  onChange,
  onSubmit,
  inputValue,
  clickIcon,
  placeHolder,
  iconName,
  iconBoxWidth,
  iconBoxHeight,
  iconBoxBg,
  iconWidth,
  iconHeight,
  width,
  padding,
  color = "black",
}: InputGroupProps) => {
  const [focusState, setFocusState] = useState(false);

  const onFocus = () => {
    setFocusState(true);
  };
  const onBlur = () => {
    setFocusState(false);
  };

  return (
    <S.InputGroupContainer
      focus={focusState}
      onSubmit={onSubmit}
      width={width || "100%"}
      padding={padding || "0px 4px 0px 0px"}
    >
      <Input
        placeholder={placeHolder}
        placeholderFontSize="t14"
        typography="t14"
        height="40px"
        value={inputValue}
        onChange={(e) => onChange(e.target.value.trim())}
        onBlur={onBlur}
        onFocus={onFocus}
        color={color}
        style={{
          border: "none",
          flex: 1,
        }}
      />
      <Flex
        justify="center"
        align="center"
        style={{
          background: iconBoxBg,
          width: iconBoxWidth,
          height: iconBoxHeight,
          borderRadius: "8px",
        }}
      >
        <Icon
          style={{
            cursor: "pointer",
          }}
          name={iconName}
          onClick={clickIcon}
          width={iconWidth}
          height={iconHeight}
        />
      </Flex>
    </S.InputGroupContainer>
  );
};

export default InputGroup;
