import ReactDOM from "react-dom/client";
import { Global } from "@emotion/react";
import reportWebVitals from "./reportWebVitals";
import { RecoilRoot } from "recoil";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import App from "./App";
// import globalStyles from "@style/globalStyles";
// import { AlertContextProvider } from "./context/AlertContext";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import globalStyles from "@style/globalStyles";
// import "./toast-container.css";

const client = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
  <>
    <Global styles={globalStyles} />
    <RecoilRoot>
      <QueryClientProvider client={client}>
        {/* <RecoilCustomPersist> */}
        {/* <AlertContextProvider> */}
        <ToastContainer
          position="top-center"
          autoClose={1000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          theme="colored"
        />
        <App />
        {/* </AlertContextProvider> */}
        {/* </RecoilCustomPersist> */}
      </QueryClientProvider>
    </RecoilRoot>
  </>

  // </React.StrictMode>
);

reportWebVitals();
