import { css } from "@emotion/react";
import { colorPalette, colors } from "@style/colorPalette";
import {
  LAPTOP_MAX_WIDTH,
  LAPTOP_MIN_WIDTH,
  MEDIA_QUERIES,
  MOBILE_MAX_WIDTH,
  TABLET_MAX_WIDTH,
  TABLET_MIN_WIDTH,
} from "@constants/breakpoint";

export default css`
  ${colorPalette}

  /* html {
    font-size: 10px; // 기본 Laptop
    ${MEDIA_QUERIES.MOBILE} {
      font-size: 8px;
    }
    ${MEDIA_QUERIES.TABLET} {
      font-size: 9px;
    }
    ${MEDIA_QUERIES.DESKTOP} {
      font-size: 11px;
    }
  } */

  html {
    font-size: 10px; // 기본 Laptop
  }

  @media (max-width: ${MOBILE_MAX_WIDTH}px) {
    html {
      font-size: 6px;
    }
  }

  @media (min-width: ${TABLET_MIN_WIDTH}px) and (max-width: ${TABLET_MAX_WIDTH}px) {
    html {
      font-size: 7px;
    }
  }

  @media (min-width: ${LAPTOP_MIN_WIDTH}px) and (max-width: ${LAPTOP_MAX_WIDTH}px) {
    html {
      font-size: 8px;
    }
  }

  :root {
    --dimmed-zindex: 10;
    --alert-zindex: 11;
  }

  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  /* strong, */
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    display: block;
  }

  body {
    line-height: 1;
    font-size: inherit;
    /* min-width: 320px; */
    width: 100%;
    background-color: ${colors.ivory97};
  }

  #root {
    /* display: flex;
    flex-direction: column;
    min-height: 100vh; */
  }
  ol,
  ul {
    list-style: none;
  }

  blockquote,
  q {
    quotes: none;
  }
  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: "";
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  /* button tag reset */

  button {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    color: inherit;
    font: inherit;
    line-height: normal;
    cursor: pointer;
  }

  /* a tag reset */
  a {
    color: inherit;
    text-decoration: inherit;
  }
`;
