import React, { useState } from "react";
import { toast } from "react-toastify";
import { Outlet, useNavigate } from "react-router-dom";

import * as S from "./Layout.style";
import { Header } from "@organisms/Header/Header";
import useDeviceType from "@hooks/useDeviceType";
import InputGroup from "@molecules/Group/InputGroup";
import { colors } from "@style/colorPalette";

export interface LayoutProps {
  hasHeader?: boolean;
  // center?: boolean;
  // direction: "horizontal" | "vertical";
  // width: string;
}

const Layout = ({ hasHeader = false }: LayoutProps) => {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    setSearchOpen(false);
  };

  const [searchOpen, setSearchOpen] = useState(false);
  const toggleSearch = () => {
    setSearchOpen(!searchOpen);
    setMenuOpen(false);
    setSearchValue("");
  };

  const { deviceType } = useDeviceType();

  const [searchValue, setSearchValue] = useState("");

  const onChange = (value: string) => {
    setSearchValue(value);
  };
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // console.log(searchValue, "보내짐");
    if (searchValue === "") {
      toast.info("검색어를 입력해주세요.");
    } else {
      navigate(`/search?query=${encodeURIComponent(searchValue)}`);
      setSearchOpen(false);
    }
  };

  const clickIcon = () => {
    navigate(`/search?query=${encodeURIComponent(searchValue)}`);
    setSearchOpen(false);
  };

  return (
    <>
      {hasHeader && (
        <Header toggleMenu={toggleMenu} toggleSearch={toggleSearch} />
      )}
      <S.Container hasHeader={hasHeader}>
        {(deviceType === "mobile" || deviceType === "tablet") && (
          <S.MobileMenu menuOpen={menuOpen}>
            <div className="item">메뉴 들어감</div>
          </S.MobileMenu>
        )}

        {
          (deviceType === "mobile" || deviceType === "tablet") && (
            <S.MoblieSearch searchOpen={searchOpen}>
              <S.MobileSearchWrapper searchOpen={searchOpen} full>
                <InputGroup
                  onChange={onChange}
                  onSubmit={onSubmit}
                  inputValue={searchValue}
                  clickIcon={clickIcon}
                  iconName="IconHeaderSearch"
                  placeHolder="상담마스터 검색하기"
                  iconBoxWidth="34px"
                  iconBoxHeight="34px"
                  iconBoxBg={colors.main}
                  width="100%"
                />
              </S.MobileSearchWrapper>
            </S.MoblieSearch>
          )
          // MoblieSearch
        }

        <Outlet />
      </S.Container>
    </>
  );
};

export default Layout;
