import * as S from "./Division.style";

export interface DivisionProps {
  width?: string;
}

const Division = ({ width }: DivisionProps) => {
  return <S.Division width={width} />;
};

export default Division;
