import * as S from "./Spacer.style";

export interface SpacingProps {
  space: string;
  direction?: "vertical" | "horizontal";
}

const Spacer = ({ space, direction = "vertical" }: SpacingProps) => {
  return <S.Spacer space={space} direction={direction} />;
};

export default Spacer;
