import styled from "@emotion/styled";

import { LayoutProps } from "./Layout";
import { colors } from "@style/colorPalette";
import Flex from "@atoms/Flex/Flex";

export const Container = styled.div<LayoutProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  padding-bottom: 5rem;
  width: 100%;
  margin-top: ${({ hasHeader }) => (hasHeader ? "2rem" : "0rem")};
`;

export const MobileMenu = styled.div<{
  menuOpen: boolean;
}>`
  width: ${({ menuOpen }) => (menuOpen ? "100%" : "0")};
  min-height: 100vh;
  background-color: ${colors.ivory97};
  position: fixed;
  z-index: 5;
  top: 65px;
  left: 0;
  overflow: hidden;
  transition: width 0.6s ease-in-out; /* 부드럽게 슬라이드 */

  /* ${({ menuOpen }) =>
    menuOpen
      ? `
    animation: expandWidth 0.6s ease-in-out forwards;
  `
      : `
    animation: collapseWidth 0.6s ease-in-out forwards;
  `}

  @keyframes expandWidth {
    from {
      width: 0%;
    }
    to {
      width: 100%;
    }
  }

  @keyframes collapseWidth {
    from {
      width: 100%;
    }
    to {
      width: 0%;
    }
  } */

  .item {
    /* opacity: ${({ menuOpen }) => (menuOpen ? 1 : 0)};
    ${({ menuOpen }) =>
      menuOpen
        ? `
    animation: toOpacity1 0.6s;
  `
        : `
    animation: toOpacity0 0.6s;
  `}

    @keyframes toOpacity1 {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }

    @keyframes toOpacity0 {
      from {
        opacity: 1;
      }
      to {
        opacity: 0;
      }
    } */

    opacity: ${({ menuOpen }) => (menuOpen ? 1 : 0)};
    transition: opacity 0.6s; /* 아이템의 투명도 전환 */
  }
`;

export const MoblieSearch = styled.div<{
  searchOpen: boolean;
}>`
  width: 100%;
  height: ${({ searchOpen }) => (searchOpen ? "7rem" : "0")};
  opacity: ${({ searchOpen }) => (searchOpen ? 1 : 0)};

  box-sizing: border-box;
  position: fixed;
  z-index: 5;
  top: 65px;
  left: 0;

  overflow: hidden;
  transition: height 0.6s, opacity 0.6s;

  /* ${({ searchOpen }) =>
    searchOpen
      ? `
    animation: expandHeight 0.6s;
  `
      : `
    animation: collapseHeight 0.6s;
  `}

  @keyframes expandHeight {
    from {
      height: 0rem;
    }
    to {
      height: 7rem;
    }
  }

  @keyframes collapseHeight {
    from {
      height: 7rem;
    }
    to {
      height: 0rem;
    }
  } */
`;

export const MobileSearchWrapper = styled(Flex)<{ searchOpen: boolean }>`
  opacity: ${({ searchOpen }) => (searchOpen ? 1 : 0)};
  transition: opacity 0.6s, opacity 0.6s;
  /* padding: 2rem 0; */
  /* padding: 2rem; */
  /* ${({ searchOpen }) =>
    searchOpen
      ? `
    animation: toOpacity1 0.6s;
  `
      : `
    animation: toOpacity0 0.6s;
  `}

  @keyframes toOpacity1 {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes toOpacity0 {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  } */
`;
