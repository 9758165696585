import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import { useState } from "react";

import "swiper/css";
import "swiper/css/pagination";

import * as S from "./TopTenTeacherSectioin.style";

import TeacherSection from "./TeacherSection";
import Flex from "@atoms/Flex/Flex";
import Text from "@atoms/Text/Text";
import CustomNavigation from "@molecules/Navigation/CustomNavigation";
import Spacer from "@atoms/Spacer/Spacer";
import { TOP10TEACHER } from "@data";
import TeacherSimpleCard from "@molecules/Card/TeacherSimpleCard";
import useDeviceType from "@hooks/useDeviceType";
import Left from "@atoms/Navigation/Left";
import Right from "@atoms/Navigation/Right";

const TRANSITION_SPEED = 1000;

const TopTenTeacherSection = () => {
  const { deviceType } = useDeviceType();

  const [swiper, setSwiper] = useState<SwiperClass | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(0);

  const getPrevNumber = () => {
    if (deviceType === "mobile") return 2;
    if (deviceType === "tablet") return 4;
    return 5;
  };
  const SLIDES_TO_MOVE = getPrevNumber();

  const totalPages = Math.ceil(10 / SLIDES_TO_MOVE);

  const handleNext = () => {
    if (swiper) {
      const newIndex = Math.min(currentPage + 1, totalPages - 1);
      swiper.slideTo(newIndex * SLIDES_TO_MOVE, TRANSITION_SPEED);
      setCurrentPage(newIndex);
    }
  };

  const handlePrev = () => {
    if (swiper) {
      const newIndex = Math.max(currentPage - 1, 0);
      swiper.slideTo(newIndex * SLIDES_TO_MOVE, TRANSITION_SPEED);
      setCurrentPage(newIndex);
    }
  };

  return (
    <TeacherSection
      title={
        <Flex justify="center">
          <Text typography="t30" color="main">
            최근 3개월 <strong>베스트 TOP 10인</strong> 선정
          </Text>
        </Flex>
      }
    >
      <S.SwiperContainer>
        <CustomNavigation
          className="custom-prev"
          direction="left"
          navigationAbsolute={false}
          disabled={currentPage === 0 ? true : false}
          handleClick={handlePrev}
        >
          {/* <Icon name="IconCircleArrowLeft" width="3.9rem" height="3.9rem" /> */}
          <Left disabled={currentPage === 0 ? true : false} />
        </CustomNavigation>

        <Spacer space="3.5rem" direction="horizontal" />

        <Swiper
          slidesPerView={getPrevNumber()}
          spaceBetween={13}
          onSwiper={(swiper: SwiperClass) => setSwiper(swiper)}
          modules={[Pagination, Navigation]}
          allowTouchMove={false}
        >
          {TOP10TEACHER.map((teacher) => (
            <SwiperSlide key={teacher.id}>
              <TeacherSimpleCard teacher={teacher} />
            </SwiperSlide>
          ))}
        </Swiper>

        <Spacer space="3.5rem" direction="horizontal" />

        <CustomNavigation
          direction="left"
          disabled={currentPage === totalPages - 1}
          handleClick={handleNext}
          navigationAbsolute={false}
        >
          {/* <Icon name="IconCircleArrowRight" width="3.9rem" height="3.9rem" />
           */}
          <Right disabled={currentPage === totalPages - 1} />
        </CustomNavigation>

        <div className="custom-pagenation">
          {Array(totalPages)
            .fill("")
            .map((_, i) => (
              <div
                key={i}
                className={
                  i === currentPage ? "circle circle-active" : "circle"
                }
              ></div>
            ))}
        </div>
      </S.SwiperContainer>
    </TeacherSection>
  );
};

export default TopTenTeacherSection;
