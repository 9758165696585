import React from "react";

import * as S from "./CustomNavigation.style";

interface CustomNavigationProps {
  disabled: boolean;
  handleClick?: () => void;
  direction?: "left" | "right";
  children: React.ReactNode;
  navigationAbsolute: boolean;
  className?: string;
}

const CustomNavigation = ({
  disabled,
  handleClick,
  children,
  navigationAbsolute,
  direction = "left",
  className,
}: CustomNavigationProps) => {
  return (
    <S.NavigationWrapper
      className={className}
      onClick={!disabled ? handleClick : undefined}
      disabled={disabled}
      navigationAbsolute={navigationAbsolute}
      direction={direction}
    >
      {children}
    </S.NavigationWrapper>
  );
};

export default CustomNavigation;
