import React, { useState } from "react";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";

import "swiper/css";
import "swiper/css/pagination";

import * as S from "./MainBanner.style";
import { MAIN_BANNER } from "@constants/banner";
import CustomNavigation from "@/components/molecules/Navigation/CustomNavigation";
import Flex from "@/components/atoms/Flex/Flex";
import Image from "@/components/atoms/Image/Image";
import useDeviceType from "@/hooks/useDeviceType";
import Left from "@/components/atoms/Navigation/Left";
import Right from "@/components/atoms/Navigation/Right";

const MainBanner = () => {
  const { deviceType } = useDeviceType();
  const [swiper, setSwiper] = useState<SwiperClass | null>(null);

  // Icon state management

  return (
    <S.MainBannerContainer>
      <Swiper
        pagination={{
          type: "fraction",
        }}
        modules={[Pagination, Navigation, Autoplay]}
        onSwiper={(swiper: SwiperClass) => setSwiper(swiper)}
        loop={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        {MAIN_BANNER.map((banner, i) => (
          <SwiperSlide key={i}>
            <Flex
              style={{
                width: "100%",
                height: "100%",
              }}
            >
              <Image
                width="100%"
                src={banner.img}
                alt={`banner-${i}`}
                height="100%"
                objectFit="cover"
              />
            </Flex>
          </SwiperSlide>
        ))}

        {deviceType !== "mobile" && (
          <CustomNavigation
            className="custom-prev"
            direction="left"
            navigationAbsolute={true}
            disabled={false}
            handleClick={() => swiper?.slidePrev()}
          >
            {/* <Icon
              name={iconLeft}
              width="3.9rem"
              height="3.9rem"
              onMouseEnter={() => setIconLeft("IconCircleArrowLeftHover")}
              onMouseLeave={() => setIconLeft("IconCircleArrowLeft")}
            /> */}
            <Left disabled={false} />
          </CustomNavigation>
        )}

        {deviceType !== "mobile" && (
          <CustomNavigation
            className="custom-next"
            navigationAbsolute={true}
            disabled={false}
            direction="right"
            handleClick={() => swiper?.slideNext()}
          >
            <Right disabled={false} />
          </CustomNavigation>
        )}
      </Swiper>
    </S.MainBannerContainer>
  );
};

export default MainBanner;
