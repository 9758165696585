import styled from "@emotion/styled";

export const HeartContainer = styled.div<{
  top?: string;
  left?: string;
  right?: string;
  bottom?: string;
  size: "large" | "small";
}>`
  position: absolute;
  top: ${({ top }) => (top ? top : "")};
  left: ${({ left }) => (left ? left : "")};
  right: ${({ right }) => (right ? right : "")};
  bottom: ${({ bottom }) => (bottom ? bottom : "")};
  /* width: ${({ size }) => (size === "large" ? "70px" : "38px")};
    height: ${({ size }) => (size === "large" ? "70px" : "38px")}; */
`;
