export const useSignIn = () => {
  const loginNaver = () => {
    console.log("네이버로 로그인");
  };

  const loginApple = () => {
    console.log("애플로 로그인");
  };

  const loginKakao = () => {
    console.log("카카오로 로그인");
  };

  const loginGoogle = () => {
    console.log("구글로 로그인");
  };

  return {
    loginNaver,
    loginApple,
    loginKakao,
    loginGoogle,
  };
};
