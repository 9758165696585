import { css } from "@emotion/react";

export const typographyMap = {
  t10: css`
    font-size: 1rem;
  `,
  t12: css`
    font-size: 1.2rem;
  `,
  t13: css`
    font-size: 1.3rem;
  `,
  t14: css`
    font-size: 1.4rem;
  `,
  t15: css`
    font-size: 1.5rem;
  `,
  t16: css`
    font-size: 1.6rem;
  `,
  t18: css`
    font-size: 1.8rem;
  `,
  t19: css`
    font-size: 1.9rem;
  `,
  t20: css`
    font-size: 2rem;
  `,
  t22: css`
    font-size: 2.2rem;
  `,
  t24: css`
    font-size: 2.4rem;
  `,
  t26: css`
    font-size: 2.6rem;
  `,
  t28: css`
    font-size: 2.8rem;
  `,
  t30: css`
    font-size: 3rem;
  `,
  t32: css`
    font-size: 3.2rem;
  `,
  t35: css`
    font-size: 3.5rem;
  `,
  t40: css`
    font-size: 4rem;
  `,
  t55: css`
    font-size: 5.5rem;
  `,
};

export type TypographyType = keyof typeof typographyMap;
