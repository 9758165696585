import * as S from "./BestReviewCard.style";
import { ReviewCardProps } from "@/models";
import Flex from "@atoms/Flex/Flex";
import Image from "@atoms/Image/Image";
import Icon from "@atoms/Icon/Icon";
import Text from "@atoms/Text/Text";
import Spacer from "@atoms/Spacer/Spacer";
import CategoryBox from "@molecules/Category/CategoryBox";
import Right from "@atoms/Navigation/Right";

const BestReviewCard = ({ review }: { review: ReviewCardProps }) => {
  return (
    <S.BestReviewCardContainer>
      {/* top */}
      <Flex align="center" gap={1}>
        <Image
          src={review.userProfileImg}
          alt=""
          width="5.5rem"
          height="5.5rem"
          borderRadius="50%"
        />
        <Flex direction="column" gap={0.8} justify="center">
          <Flex gap={0.2}>
            <Icon name="IconReviewStar" width="1.86rem" height="1.7rem" />
            <Icon name="IconReviewStar" width="1.86rem" height="1.7rem" />
            <Icon name="IconReviewStar" width="1.86rem" height="1.7rem" />
            <Icon name="IconReviewStar" width="1.86rem" height="1.7rem" />
            <Icon name="IconReviewStar" width="1.86rem" height="1.7rem" />
          </Flex>
          <Flex gap={0.4} align="center">
            <Text typography="t16">{review.userNickName}</Text>
            <CategoryBox>
              <Text typography="t16" color="grey53">
                {review.counselKind}
              </Text>
            </CategoryBox>

            <Text typography="t16" color="grey53">
              {review.counseldAt}
            </Text>
          </Flex>
        </Flex>
      </Flex>

      <Spacer space="3rem" />
      <Text typography="t20" fontWeight={600}>
        {review.title}
      </Text>

      <Spacer space="2.7rem" />

      <Text
        typography="t16"
        style={{
          whiteSpace: "pre-line",
          overflow: "hidden",
          lineHeight: "160%",
          textOverflow: "ellipsis",
          height: "15rem",
          display: "-webkit-box", // 여러 줄 말줄임표를 위해 추가
          WebkitLineClamp: 6, // 최대 줄 수 설정  --> 이건 -webkit-만 가능 -o-, -moz-는 안됨(직접 구현 필요)
          WebkitBoxOrient: "vertical", // 세로 방향 클램프 설정
        }}
      >
        {review.description}
      </Text>

      <Spacer space="3rem" />
      <div className="card">
        <Image
          src={review.teacher.img}
          alt=""
          width="8.1rem"
          height="6.8rem"
          borderRadius="0.5rem"
        />
        <Spacer space="0.8rem" direction="horizontal" />
        <Flex
          direction="column"
          justify="center"
          gap={0.6}
          style={{
            flex: 1,
          }}
        >
          <Flex gap={0.6} align="center">
            <Text typography="t20">소나무</Text>
            <Text typography="t16" color="grey53">
              126번
            </Text>
          </Flex>
          <Flex gap={0.3} align="center">
            <Text typography="t16">1500</Text>
            <Icon name="IconCoin" width="1.5rem" height="1.5rem" />
            <Text typography="t12" color="grey53">
              30초
            </Text>
          </Flex>
        </Flex>

        <Flex align="center" gap={0.9}>
          <Icon
            name="IconReviewCardTeacherHeartFill"
            // IconReviewCardTeacherHeartNone
            width="2.8rem"
            height="2.3rem"
          />
          {/* <Icon name="IconCircleArrowRight" width="3.3rem" height="3.3rem" /> */}
          <Right disabled={false} />
        </Flex>
      </div>
    </S.BestReviewCardContainer>
  );
};

export default BestReviewCard;
