import { useQueryParams } from "@/hooks/useQueryParams";

import * as S from "./SearchKeywordLayout.style";
import { useState } from "react";
import Flex from "@atoms/Flex/Flex";
import Text from "@atoms/Text/Text";
import Spacer from "@atoms/Spacer/Spacer";
import Icon from "@atoms/Icon/Icon";
import Grid from "@atoms/Grid/Grid";
import { TOPONEPERCENTTEACHER } from "@/data";
import TeacherCard from "@molecules/Card/TeacherCard";
import EventBanner from "@organisms/Banner/EventBanner";
import TeacherSection from "@organisms/Section/TeacherSection";
import useDeviceType from "@hooks/useDeviceType";
import SearchDropDown from "@molecules/DropDown/SearchDropDown";
import { DROPDOWN_ARRAY } from "@constants/dropDown";
import RecommendTeacherSection from "@organisms/Section/RecommendTeacherSection";

function SearchKeywordLayout() {
  const { deviceType } = useDeviceType();
  const { query } = useQueryParams();

  const [activeFilter, setActiveFilter] = useState("인기순");
  const [openDropDown, setOpenDropDown] = useState(false);

  // TODO DropDown 따로 컴포넌트 빼서 밖에 클릭 시 닫히기
  const toggleDropDown = () => {
    setOpenDropDown(!openDropDown);
  };

  const clickActive = (filter: string) => {
    setActiveFilter(filter);
    setOpenDropDown(false);
  };
  return (
    <S.SeardhKeywordLayout>
      <Flex direction="column" align="center">
        <Text typography="t28" fontWeight={600} color="main">
          {query}의 검색 결과가 없습니다.
        </Text>
        <Spacer space="0.4rem" />
        <Text typography="t16" color="main">
          다시 한번 상담 마스터를 검색해주세요.
        </Text>
      </Flex>
      <Spacer space="3.7rem" />

      <S.FindTeacherContainer>
        <Text typography="t28" fontWeight={600} color="main">
          상담마스터를 찾았습니다!
        </Text>

        <Flex
          style={{
            flex: 1,
            width: "100%",
            margin: "1rem 0",
            cursor: "pointer",
            position: "relative",
          }}
          justify="end"
          align="center"
          gap={0.7}
          onClick={toggleDropDown}
        >
          <Text typography="t20" color="grey56">
            {activeFilter}
          </Text>
          <Icon
            name={openDropDown ? "IconDropDownUp" : "IconDropDownDown"}
            width="1.8rem"
            height="1rem"
          />

          {openDropDown && (
            <SearchDropDown
              data={DROPDOWN_ARRAY}
              clickActive={clickActive}
              activeFilter={activeFilter}
            />
          )}
        </Flex>

        <TeacherSection>
          <Grid
            columns={deviceType === "mobile" ? 1 : 2}
            columnGap={2.4}
            rowGap={3.2}
          >
            {TOPONEPERCENTTEACHER.map((teacher) => (
              <TeacherCard size="large" key={teacher.id} teacher={teacher} />
            ))}
          </Grid>
        </TeacherSection>

        <Spacer space="8.5rem" />
      </S.FindTeacherContainer>

      <EventBanner />
      <Spacer space="4rem" />
      <RecommendTeacherSection />
    </S.SeardhKeywordLayout>
  );
}

export default SearchKeywordLayout;
