import { useNavigate } from "react-router-dom";

import Text from "@atoms/Text/Text";
import * as S from "./FindIdPwLayout.style";
import Spacer from "@atoms/Spacer/Spacer";
import Flex from "@atoms/Flex/Flex";
import Button from "@atoms/Button/Button";

function FindIdPwLayout() {
  const navigate = useNavigate();

  const clickNiceId = () => {
    navigate("/find/result");
  };
  return (
    <S.FindContainer>
      <Text typography="t24" fontWeight={600} color="main">
        아이디/비밀번호 찾기
      </Text>
      <S.StyledIcon
        name="IconBackArrow"
        onClick={() => navigate(-1)}
        width="1.6rem"
        height="2.7rem"
      />

      <Spacer space="10.4rem" />

      <Flex direction="column" full>
        <Text typography="t30" color="main">
          가입한 이메일을 찾으시려면 <br />
          본인인증을 해주세요
        </Text>
      </Flex>
      <Spacer space="24rem" />
      <Button
        onClick={clickNiceId}
        color="next"
        width="100%"
        height="7rem"
        typography="t24"
        //   disabled={!noError}
        hover={false}
      >
        본인 인증하기
      </Button>
    </S.FindContainer>
  );
}

export default FindIdPwLayout;
