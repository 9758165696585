import { TypographyType } from "@style/typography";
import * as icon from "@icon";
import Flex from "@atoms/Flex/Flex";
import Text from "@atoms/Text/Text";
import Icon from "@atoms/Icon/Icon";

type IconNameType = keyof typeof icon;

interface TextArrowGroupProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  iconName: IconNameType;
  iconWidth: string;
  iconHeight: string;
  typography?: TypographyType;
}

const TextArrowGroup = ({
  title,
  iconName,
  typography = "t24",
  iconWidth,
  iconHeight,
  onClick,
}: TextArrowGroupProps) => {
  return (
    <Flex
      align="center"
      style={{
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <Text
        typography={typography}
        color="main"
        fontWeight={600}
        style={{
          marginRight: "4px",
        }}
      >
        {title}
      </Text>
      <Icon name={iconName} width={iconWidth} height={iconHeight} />
    </Flex>
  );
};

export default TextArrowGroup;
