import { TERMS } from "@constants/apply";
import { useApply } from "@hooks/useApply";

import * as S from "./ApplyForm.style";
import Text from "@atoms/Text/Text";
import Spacer from "@atoms/Spacer/Spacer";
import Flex from "@atoms/Flex/Flex";
import Icon from "@atoms/Icon/Icon";
import Division from "@atoms/Division/Division";
import Button from "@atoms/Button/Button";

const ApplyForm = ({
  nextStep,
  prevStep,
}: {
  nextStep: () => void;
  prevStep: () => void;
}) => {
  const {
    termsAgreements,
    isAllCheck,
    isEssentialCheck,
    handleAllAgree,
    handleAgree,
  } = useApply();

  return (
    <S.ApplyContainer>
      <Text typography="t24" fontWeight={600} color="main">
        약관동의
      </Text>
      <S.StyledIcon
        name="IconBackArrow"
        onClick={prevStep}
        width="1.6rem"
        height="2.7rem"
      />

      <Spacer space="4.3rem" />

      <Flex
        direction="column"
        style={{
          width: "90%",
        }}
      >
        <Text
          typography="t26"
          color="main"
          style={{
            whiteSpace: "pre-line",
            lineHeight: "4rem",
          }}
        >
          {"반갑습니다!\n 만월타로신점 약관에 동의해주세요"}
        </Text>

        <Spacer space="2.1rem" />
        <Flex align="center" gap={1.2}>
          <Icon
            name={isAllCheck ? "IconApplyCircleFill" : "IconApplyCircleNone"}
            onClick={(e) => handleAllAgree(e, !isAllCheck)}
            style={{
              cursor: "pointer",
            }}
            width="3.2rem"
            height="3.2rem"
          />
          <Text typography="t24" color="black33">
            모두 동의합니다
          </Text>
        </Flex>
      </Flex>
      <Spacer space="2.5rem" />
      <Division width="100%" />
      <Spacer space="2.2rem" />

      <Flex
        direction="column"
        gap={2.2}
        style={{
          width: "90%",
        }}
      >
        {TERMS.map((term) => (
          <Flex
            gap={2}
            align="center"
            key={term.id}
            onClick={() => handleAgree(term.id, !termsAgreements[term.id])}
          >
            <Icon
              name={
                termsAgreements[term.id]
                  ? "IconApplyCheckFill"
                  : "IconApplyCheckNone"
              }
              style={{
                cursor: "pointer",
              }}
              width="1.8rem"
              height="1.8rem"
            />
            <Text typography="t22" color="black33">
              {term.title}
            </Text>
          </Flex>
        ))}
      </Flex>

      <Spacer space="7rem" />
      <Button
        onClick={nextStep}
        color="next"
        width="100%"
        height="7rem"
        typography="t24"
        disabled={!isEssentialCheck}
        hover={false}
      >
        다음
      </Button>
    </S.ApplyContainer>
  );
};

export default ApplyForm;
