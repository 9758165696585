import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "@router/ScrollToTop";
import Router from "@router/Router";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Router />
    </BrowserRouter>
  );
}

export default App;
