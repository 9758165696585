import Flex from "@atoms/Flex/Flex";
import TeacherSection from "./TeacherSection";
import Text from "@atoms/Text/Text";
import Grid from "@atoms/Grid/Grid";
import { TOPONEPERCENTTEACHER } from "@data";
import TeacherCard from "@molecules/Card/TeacherCard";
import useDeviceType from "@hooks/useDeviceType";

const CanCounselTeacherSection = () => {
  const { deviceType } = useDeviceType();
  return (
    <TeacherSection
      title={
        <Flex justify="center">
          <Text color="main" typography="t30">
            지금 <strong>실시간 상담가능한</strong> 상담마스터
          </Text>
        </Flex>
      }
      more={true}
    >
      <Grid
        columns={deviceType === "mobile" ? 1 : 2}
        columnGap={2.4}
        rowGap={3.2}
      >
        {TOPONEPERCENTTEACHER.map((teacher) => (
          <TeacherCard size="large" key={teacher.id} teacher={teacher} />
        ))}
      </Grid>
    </TeacherSection>
  );
};

export default CanCounselTeacherSection;
