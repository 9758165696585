import { MouseEvent, useState } from "react";

import { TERMS } from "@constants/apply";

export const useApply = () => {
  const [termsAgreements, setTermsAgreements] = useState(() => {
    return TERMS.reduce<Record<string, boolean>>(
      (prev, term) => ({
        ...prev,
        [term.id]: false,
      }),
      {}
    );
  });

  const isAllCheck = Object.values(termsAgreements).every((allTrue) => allTrue);

  const isEssentialCheck = Object.entries(termsAgreements)
    .filter(([key]) => key !== "3")
    .every(([, value]) => value);

  const handleAllAgree = (
    _: MouseEvent<SVGSVGElement>,
    checked: boolean
  ): void => {
    setTermsAgreements((prevTerms) => {
      return Object.keys(prevTerms).reduce(
        (prev, key) => ({
          ...prev,
          [key]: checked,
        }),
        {}
      );
    });
  };

  const handleAgree = (id: number, checked: boolean): void => {
    setTermsAgreements((prevTerms) => ({
      ...prevTerms,
      [id]: checked,
    }));
  };

  return {
    termsAgreements,
    isAllCheck,
    isEssentialCheck,
    handleAllAgree,
    handleAgree,
  };
};
