import React from "react";
import Text from "@atoms/Text/Text";
import Spacer from "@atoms/Spacer/Spacer";
import * as S from "./TeacherSection.style";
import Flex from "@atoms/Flex/Flex";
import useDeviceType from "@hooks/useDeviceType";

interface TeacherSectionProps {
  more?: boolean;
  title?: React.ReactNode;
  children: React.ReactNode;
  maxWidth?: string;
  searchedAt?: string;
}

const TeacherSection = ({
  title,
  more,
  children,
  searchedAt,
}: TeacherSectionProps) => {
  const { deviceType } = useDeviceType();
  const clickMore = () => {
    console.log("더보기 눌림");
  };
  return (
    <S.SectionContainer>
      {title}
      {title && <Spacer space="1rem" />}

      {searchedAt && (
        <>
          <Flex full justify={deviceType !== "mobile" ? "end" : "center"}>
            <Text
              typography="t20"
              color="grey77"
              style={{
                paddingRight: deviceType !== "mobile" ? "1rem" : "0",
              }}
            >
              {searchedAt}기준
            </Text>
          </Flex>

          <Spacer space="2rem" />
        </>
      )}

      {more && (
        <Flex full justify="end">
          <Text
            typography="t16"
            color="grey56"
            style={{
              marginBottom: "3px",
              paddingRight: "8px",
            }}
            onClick={clickMore}
          >
            더 보기
          </Text>
        </Flex>
      )}

      {children}
    </S.SectionContainer>
  );
};

export default TeacherSection;
