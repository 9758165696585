import { MEDIA_QUERIES } from "@/constants/breakpoint";
import { colors } from "@/style/colorPalette";
import styled from "@emotion/styled";

export const HeaderContainer = styled.div`
  width: 100%;
  height: 100px;
  position: sticky;
  top: 0;
  background-color: ${colors.ivory97};
  border-bottom: 1px solid ${colors.grey92};
  z-index: 5;
  padding: 3px 23px;
  box-sizing: border-box;

  @media ${MEDIA_QUERIES.MOBILE} {
    padding: 0px 5px;
    height: 65px;
  }
  @media ${MEDIA_QUERIES.TABLET} {
    padding: 0px 8px;
    height: 70px;
  }
  @media ${MEDIA_QUERIES.LAPTOP} {
    padding: 0px 20px;
    /* height: 130px; */
  }

  .temp {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const Hamburger = styled.ul`
  display: flex;
  gap: 2rem;
  li {
    border: 1px solid;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 1.5rem;
  }
`;
