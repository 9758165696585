import styled from "@emotion/styled";
import { LabelProps } from "./Label";
import { colors } from "@style/colorPalette";
import { typographyMap } from "@style/typography";

export const Label = styled.label<LabelProps>`
  ${({ typography = "t16" }) => typographyMap[typography]};
  font-weight: ${({ bold, fontWeight }) => (bold ? "bold" : fontWeight)};
  display: ${({ display = "inline-block" }) => display};
  color: ${({ color = "black" }) => colors[color]};
  cursor: pointer;
  padding: 0.3rem 0.5rem;
`;
