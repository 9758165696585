import { css } from "@emotion/react";

export const colorPalette = css`
  :root {
    --black: #000000;
    --black3: #050708; // apple
    --black27: #444444; // color-black
    --black33: #555555; // font-black

    --yellow50: #fee500; // kakao

    --white100: #ffffff;

    --ivory97: #faf9f5; //ivory
    --ivory95: #f2f2f2; // bg-ivory

    --main: #004540;
    --green49: #4caf50; //green
    --green40: #03c75a; // naver

    --blue56: #2e72ef; //blue

    --grey53: #888888; //font-grey2
    --grey56: #8e8e8e; //font-grey
    --grey67: #acacac; //placeholder
    --grey69: #b0b0b0; //font-grey3
    --grey77: #c4c4c4; //grey
    --grey78: #c3c9cb; // light-grey
    --grey80: #cdcdcd; // grey2
    --grey85: #ececec; // bggrey2
    --grey90: #e6e6e6; // grey3
    --grey92: #f1eee4; // border-grey
    --grey93: #ececec; // bggrey

    --red50: #ff0000; // red
    --red67: #ff5656; // red200 , tomato
    --red74: #ff8c78; // red100

    --brown33: #a88400; // brown
  }
`;

export const colors = {
  black: "var(--black)",
  black3: "var(--black3)",
  black27: "var(--black27)",
  black33: "var(--black33)",

  white100: "var(--white100)",

  yellow50: "var(--yellow50)",

  ivory97: "var(--ivory97)",
  ivory95: "var(--ivory95)",

  main: "var(--main)",
  green49: "var(--green49)",
  green40: "var(--green40)",

  blue56: "var(--blue56)",

  grey53: "var(--grey53)",
  grey56: "var(--grey56)",
  grey67: "var(--grey67)",
  grey69: "var(--grey69)",
  grey77: "var(--grey77)",
  grey78: "var(--grey78)",
  grey80: "var(--grey80)",
  grey85: "var(--grey85)",
  grey90: "var(--grey90)",
  grey92: "var(--grey92)",
  grey93: "var(--grey93)",

  red50: "var(--red50)",
  red67: "var(--red67)",
  red74: "var(--red74)",

  brown33: "var(--brown33)",
};

export type ColorsType = keyof typeof colors;
