import Grid from "@atoms/Grid/Grid";
import Text from "@atoms/Text/Text";
import Flex from "@atoms/Flex/Flex";
import TeacherSection from "./TeacherSection";
import { TOPONEPERCENTTEACHER } from "@data";
import TeacherCard from "@molecules/Card/TeacherCard";
import useDeviceType from "@hooks/useDeviceType";

const TopOnePercentTeacherSection = () => {
  const { deviceType } = useDeviceType();
  return (
    <TeacherSection
      title={
        <Flex justify="center">
          <Text typography="t30" color="main">
            <strong>고객만족도 상위</strong> 1% 상담마스터
          </Text>
        </Flex>
      }
      more={true}
    >
      <Grid
        columns={deviceType === "mobile" ? 1 : 2}
        columnGap={2.4}
        rowGap={3.2}
      >
        {TOPONEPERCENTTEACHER.map((teacher) => (
          <TeacherCard size="large" key={teacher.id} teacher={teacher} />
        ))}
      </Grid>
    </TeacherSection>
  );
};

export default TopOnePercentTeacherSection;
