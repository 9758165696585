import styled from "@emotion/styled";

import { colors } from "@style/colorPalette";

export const InputGroupContainer = styled.form<{
  focus: boolean;
  width: string;
  padding: string;
}>`
  display: flex;
  border: ${({ focus }) => (focus ? `1px solid ${colors.main}` : "none")};
  align-items: center;
  max-width: ${({ width }) => width};
  width: 100%;
  height: 42px;
  border-radius: 0.8rem;
  padding: ${({ padding }) => padding};
  background-color: ${colors.white100};
  box-sizing: border-box;
`;
