import { CSSProperties } from "react";

import { ColorsType } from "@style/colorPalette";
import { TypographyType } from "@style/typography";
import * as S from "./Text.style";

export interface TextProps extends React.HTMLAttributes<HTMLSpanElement> {
  typography: TypographyType;
  color?: ColorsType;
  display?: CSSProperties["display"];
  textAlign?: CSSProperties["textAlign"];
  fontWeight?: CSSProperties["fontWeight"];
  bold?: boolean;
  lineHeight?: CSSProperties["lineHeight"];
  children: React.ReactNode;
}

function Text({ children, ...props }: TextProps) {
  return <S.Text {...props}>{children}</S.Text>;
}

export default Text;
