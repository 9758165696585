import TeacherSection from "./TeacherSection";

import * as S from "./PopularTeacherSection.style";
import Flex from "@atoms/Flex/Flex";
import Text from "@atoms/Text/Text";
import Grid from "@atoms/Grid/Grid";
import { POPULARTEACHER } from "@data";
import TeacherCard from "@molecules/Card/TeacherCard";
import useDeviceType from "@hooks/useDeviceType";

const PopularTeacherSection = () => {
  const { deviceType } = useDeviceType();

  return (
    <S.PopularTeacherSectionContainer>
      <TeacherSection
        more={true}
        title={
          <Flex justify="center">
            <Text typography="t30" color="main" fontWeight={600}>
              인기 상담마스터 안내
            </Text>
          </Flex>
        }
      >
        <Grid columns={deviceType === "mobile" ? 1 : 2} columnGap={2.1}>
          {POPULARTEACHER.map((teacher) => (
            <TeacherCard size="large" key={teacher.id} teacher={teacher} />
          ))}
        </Grid>
      </TeacherSection>
    </S.PopularTeacherSectionContainer>
  );
};

export default PopularTeacherSection;
