import { MEDIA_QUERIES } from "@/constants/breakpoint";
import { colors } from "@/style/colorPalette";
import styled from "@emotion/styled";

export const EventBannerWrapper = styled.div`
  max-width: 1121px;
  margin: 0 auto;
  width: 100%;
  height: 117px;
  border-radius: 2rem;
  position: relative;
  overflow: hidden;

  @media ${MEDIA_QUERIES.MOBILE} {
    max-width: 300px;
    height: 50px;
  }

  @media ${MEDIA_QUERIES.TABLET} {
    max-width: 550px;
    height: 70px;
  }

  @media ${MEDIA_QUERIES.LAPTOP} {
    max-width: 900px;
    height: 90px;
  }

  .swiper-pagination {
    width: 40px;
    height: 24px;
    background-color: ${colors.grey85};
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${colors.white100};
    border-radius: 5px;
    font-size: 14px;
    gap: 3px;

    position: absolute;
    top: auto;
    left: auto;
    bottom: 13px;
    right: 27px;

    .swiper-pagination-current {
      font-weight: bold;
    }

    @media ${MEDIA_QUERIES.MOBILE} {
      width: 35px;
      height: 20px;
      font-size: 11px;
    }
  }

  .swiper-pagination-fraction {
    top: auto;
    left: auto;
    bottom: 13px;
    right: 27px;

    @media ${MEDIA_QUERIES.MOBILE} {
      bottom: 8px;
      right: 15px;
    }
  }
`;
