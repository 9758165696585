import styled from "@emotion/styled";

import { colors } from "@style/colorPalette";

export const BadgeContainer = styled.div<{
  top?: string;
  left?: string;
  right?: string;
  bottom?: string;
  size: "small" | "large";
}>`
  position: absolute;
  ${({ top }) => (top ? `top: ${top};` : "")}
  ${({ left }) => (left ? `left: ${left};` : "")}
  ${({ right }) => (right ? `right: ${right};` : "")}
  ${({ bottom }) => (bottom ? `bottom: ${bottom};` : "")}
  /* width: ${({ size }) => (size === "large" ? "80px" : "45px")};
  height: ${({ size }) => (size === "large" ? "40px" : "24px")}; */
  
  padding: ${({ size }) => (size === "large" ? "1rem " : "2rem")};
  border-radius: 5px;
  background-color: ${colors.white100};
  display: flex;
  align-items: center;
  justify-content: center;
  /* color: ${({ color }) => color}; */
`;
