import React, { CSSProperties } from "react";
import * as S from "./Flex.style";

export interface FlexProps extends React.HTMLAttributes<HTMLDivElement> {
  full?: boolean;
  align?: CSSProperties["alignItems"];
  justify?: CSSProperties["justifyContent"];
  direction?: CSSProperties["flexDirection"];
  gap?: CSSProperties["gap"];
  children: React.ReactNode;
}

const Flex = ({ children, ...props }: FlexProps) => {
  return <S.Flex {...props}>{children}</S.Flex>;
};

export default Flex;
