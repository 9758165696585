import { useNavigate } from "react-router-dom";

import * as S from "./FindIdResultLayout.style";
import Text from "@atoms/Text/Text";
import Spacer from "@atoms/Spacer/Spacer";
import Flex from "@atoms/Flex/Flex";
import Icon from "@atoms/Icon/Icon";
import Button from "@atoms/Button/Button";

function FindResultLayout() {
  const navigate = useNavigate();
  return (
    <S.FindResultContainer>
      <Text typography="t24" fontWeight={600} color="main">
        아이디 찾기
      </Text>
      <S.StyledIcon
        name="IconBackArrow"
        onClick={() => navigate(-1)}
        width="1.6rem"
        height="2.7rem"
      />

      <Spacer space="10.4rem" />

      <Flex direction="column" full>
        <Text typography="t30" color="main">
          가입하신 아이디를 찾았습니다
        </Text>
        <Spacer space="2rem" />
        <Text
          typography="t16"
          color="grey56"
          style={{
            paddingLeft: "0.8rem",
          }}
        >
          본인 정보가 맞으시다면 이 계정으로 로그인해주세요.
        </Text>
      </Flex>

      <Spacer space="2.5rem" />
      <S.UserInfo>
        <Icon name="IconUserCircle" width="8.5rem" height="8.5rem" />
        <Flex direction="column" gap={0.8}>
          <Text typography="t20" fontWeight={200} color="grey53">
            rm***@na***.com
          </Text>
          <Text typography="t20" fontWeight={200} color="grey53">
            010-****-5034
          </Text>
          <Text typography="t20" fontWeight={200} color="grey53">
            이메일연동
          </Text>
        </Flex>
      </S.UserInfo>

      <Spacer space="8.2rem" />

      <Button
        onClick={() => navigate("/login")}
        color="next"
        width="100%"
        height="7rem"
        typography="t24"
        hover={false}
      >
        이메일로 로그인하기
      </Button>

      <Spacer space="1.2rem" />

      <Button
        onClick={() => navigate("/reset")}
        color="cancel"
        width="100%"
        height="7rem"
        typography="t24"
        hover={false}
        weak
      >
        비밀번호 재설정하기
      </Button>
    </S.FindResultContainer>
  );
}

export default FindResultLayout;
