import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

import * as S from "./BestReviewSection.style";
import Flex from "@atoms/Flex/Flex";
import Text from "@atoms/Text/Text";
import Spacer from "@atoms/Spacer/Spacer";
import { TOP5REVIEW } from "@data";
import BestReviewCard from "@organisms/Card/BestReviewCard";

const BestReviewSection = () => {
  return (
    <S.BestReviewContainer>
      <Flex direction="column">
        <Text typography="t40" color="white100">
          베스트후기 TOP5
        </Text>

        <Spacer space="1.2rem" />

        <Swiper
          slidesPerView={"auto"}
          spaceBetween={15}
          style={{
            width: "100%",
            height: "100%",
          }}
        >
          {TOP5REVIEW.map((review, i) => (
            <SwiperSlide key={i}>
              <BestReviewCard review={review} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Flex>
    </S.BestReviewContainer>
  );
};

export default BestReviewSection;
