import { ReviewCardProps, TeacherCardProps } from "@models";

export const TOPONEPERCENTTEACHER: TeacherCardProps[] = [
  {
    id: 1,
    name: "심채운0",
    status: "상담중",
    rating: "4.2",
    price: "1,500",
    tags: ["연애"],
    isNew: true,
    categories: ["#단호박 공수", "#직설적인"],
    img: "https://images.unsplash.com/photo-1731100062072-3904a79ff817?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHx0b3BpYy1mZWVkfDR8TThqVmJMYlRSd3N8fGVufDB8fHx8fA%3D%3D",
    kind: "신점",
    like: false,
  },
  {
    id: 2,
    name: "심채운1",
    status: "상담가능",
    rating: " 5.8",
    price: "1,450",
    tags: ["이별", "속마음"],
    isNew: true,
    categories: ["#단호박 공수", "#직설적인"],
    img: "https://images.unsplash.com/photo-1731020202106-ba0ff3ef8cae?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHx0b3BpYy1mZWVkfDh8TThqVmJMYlRSd3N8fGVufDB8fHx8fA%3D%3D",
    kind: "사주",
    like: true,
  },
  {
    id: 3,
    name: "심채운2",
    status: "부재중",
    rating: "5.0",
    price: "1,200",
    tags: ["이별", "다이어트", "부동산"],
    isNew: true,
    categories: ["#단호박 공수", "#직설적인"],
    img: "https://images.unsplash.com/photo-1731009918561-3f8b6fbc0e63?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHx0b3BpYy1mZWVkfDExfE04alZiTGJUUndzfHxlbnwwfHx8fHw%3D",
    kind: "사주",
    like: false,
  },
  {
    id: 4,
    name: "심채운3",
    status: "상담중",
    rating: "5.0",
    price: "900",
    tags: ["연애", "직장", "직장", "재회"],
    isNew: true,
    categories: ["#단호박 공수", "#직설적인"],
    img: "https://images.unsplash.com/photo-1730888515663-5058024f2e60?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHx0b3BpYy1mZWVkfDE4fE04alZiTGJUUndzfHxlbnwwfHx8fHw%3D",
    kind: "신점",
    like: true,
  },
  {
    id: 5,
    name: "심채운4",
    status: "상담가능",
    rating: "5.0",
    price: "1,000",
    tags: ["연애", "재회"],
    isNew: true,
    categories: ["#단호박 공수", "#직설적인"],
    img: "https://images.unsplash.com/photo-1712231806836-5c2f25c0b735?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHx0b3BpYy1mZWVkfDE5fE04alZiTGJUUndzfHxlbnwwfHx8fHw%3D",
    kind: "타로",
    like: true,
  },
];

export const TOP10TEACHER: TeacherCardProps[] = [
  {
    id: 1,
    name: "심채운",
    status: "상담중",
    rating: "4.2",
    price: "1,500",
    tags: ["연애"],
    isNew: true,
    categories: ["#단호박 공수", "#직설적인"],
    img: "https://images.unsplash.com/photo-1731100062072-3904a79ff817?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHx0b3BpYy1mZWVkfDR8TThqVmJMYlRSd3N8fGVufDB8fHx8fA%3D%3D",
    kind: "신점",
    like: false,
  },
  {
    id: 2,
    name: "심채운1",
    status: "상담가능",
    rating: " 5.8",
    price: "1,450",
    tags: ["이별", "속마음"],
    isNew: true,
    categories: ["#단호박 공수", "#직설적인"],
    img: "https://images.unsplash.com/photo-1731020202106-ba0ff3ef8cae?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHx0b3BpYy1mZWVkfDh8TThqVmJMYlRSd3N8fGVufDB8fHx8fA%3D%3D",
    kind: "사주",
    like: true,
  },
  {
    id: 3,
    name: "심채운2",
    status: "부재중",
    rating: "5.0",
    price: "1,200",
    tags: ["이별", "다이어트", "부동산"],
    isNew: true,
    categories: ["#단호박 공수", "#직설적인"],
    img: "https://images.unsplash.com/photo-1731009918561-3f8b6fbc0e63?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHx0b3BpYy1mZWVkfDExfE04alZiTGJUUndzfHxlbnwwfHx8fHw%3D",
    kind: "사주",
    like: false,
  },
  {
    id: 4,
    name: "심채운3",
    status: "상담중",
    rating: "5.0",
    price: "900",
    tags: ["연애", "직장", "직장", "재회"],
    isNew: true,
    categories: ["#단호박 공수", "#직설적인"],
    img: "https://images.unsplash.com/photo-1730888515663-5058024f2e60?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHx0b3BpYy1mZWVkfDE4fE04alZiTGJUUndzfHxlbnwwfHx8fHw%3D",
    kind: "신점",
    like: true,
  },
  {
    id: 5,
    name: "심채운4",
    status: "상담가능",
    rating: "5.0",
    price: "1,000",
    tags: ["연애", "재회"],
    isNew: true,
    categories: ["#단호박 공수", "#직설적인"],
    img: "https://images.unsplash.com/photo-1712231806836-5c2f25c0b735?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHx0b3BpYy1mZWVkfDE5fE04alZiTGJUUndzfHxlbnwwfHx8fHw%3D",
    kind: "타로",
    like: true,
  },
  {
    id: 6,
    name: "심채운5",
    status: "상담중",
    rating: "4.2",
    price: "1,500",
    tags: ["연애"],
    isNew: true,
    categories: ["#단호박 공수", "#직설적인"],
    img: "https://images.unsplash.com/photo-1731100062072-3904a79ff817?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHx0b3BpYy1mZWVkfDR8TThqVmJMYlRSd3N8fGVufDB8fHx8fA%3D%3D",
    kind: "신점",
    like: false,
  },
  {
    id: 7,
    name: "심채운6",
    status: "상담가능",
    rating: " 5.8",
    price: "1,450",
    tags: ["이별", "속마음"],
    isNew: true,
    categories: ["#단호박 공수", "#직설적인"],
    img: "https://images.unsplash.com/photo-1731020202106-ba0ff3ef8cae?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHx0b3BpYy1mZWVkfDh8TThqVmJMYlRSd3N8fGVufDB8fHx8fA%3D%3D",
    kind: "사주",
    like: true,
  },
  {
    id: 8,
    name: "심채운7",
    status: "부재중",
    rating: "5.0",
    price: "1,200",
    tags: ["이별", "다이어트", "부동산"],
    isNew: true,
    categories: ["#단호박 공수", "#직설적인"],
    img: "https://images.unsplash.com/photo-1731009918561-3f8b6fbc0e63?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHx0b3BpYy1mZWVkfDExfE04alZiTGJUUndzfHxlbnwwfHx8fHw%3D",
    kind: "사주",
    like: false,
  },
  {
    id: 9,
    name: "심채운8",
    status: "상담중",
    rating: "5.0",
    price: "900",
    tags: ["연애", "직장", "직장", "재회"],
    isNew: true,
    categories: ["#단호박 공수", "#직설적인"],
    img: "https://images.unsplash.com/photo-1730888515663-5058024f2e60?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHx0b3BpYy1mZWVkfDE4fE04alZiTGJUUndzfHxlbnwwfHx8fHw%3D",
    kind: "신점",
    like: true,
  },
  {
    id: 10,
    name: "심채운9",
    status: "상담가능",
    rating: "5.0",
    price: "1,000",
    tags: ["연애", "재회"],
    isNew: true,
    categories: ["#단호박 공수", "#직설적인"],
    img: "https://images.unsplash.com/photo-1712231806836-5c2f25c0b735?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHx0b3BpYy1mZWVkfDE5fE04alZiTGJUUndzfHxlbnwwfHx8fHw%3D",
    kind: "타로",
    like: true,
  },
];

export const TOP5REVIEW: ReviewCardProps[] = [
  {
    userNickName: "두가두가",
    userProfileImg:
      "https://images.unsplash.com/photo-1720982018832-35b144516bb9?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHx0b3BpYy1mZWVkfDN8RS0tX3BuSWlyRzR8fGVufDB8fHx8fA%3D%3D",
    counselKind: "전화상담",
    counseldAt: "2024.10.20",
    title: "선생님 감사합니다1.",
    description:
      "선생님~안녕하세요 선생님과의\n 첫번째상담이었어용!\n 상대방 속마음과 생각들을 너무 정확하게\n 들을 수 있는 시간이었습니다. \n 선생님의 가장 뛰어나신 부분은 성향을 정확하게 \n 파악하시고 그거에 맞는 답변을 콕 찝어서 해주시는 것 같았어요 정말 최고완전최고완전최고완전최고완전최고완전\n 아 진짜 정말",
    teacher: {
      id: 1,
      name: "심채운",
      status: "상담중",
      rating: "4.2",
      price: "1,500",
      tags: ["연애"],
      isNew: true,
      categories: ["#단호박 공수", "#직설적인"],
      img: "https://images.unsplash.com/photo-1731020202106-ba0ff3ef8cae?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHx0b3BpYy1mZWVkfDh8TThqVmJMYlRSd3N8fGVufDB8fHx8fA%3D%3D",
      kind: "신점",
      like: false,
    },
  },

  {
    userNickName: "두가두가2",
    userProfileImg:
      "https://images.unsplash.com/photo-1731020202106-ba0ff3ef8cae?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHx0b3BpYy1mZWVkfDh8TThqVmJMYlRSd3N8fGVufDB8fHx8fA%3D%3D",
    counselKind: "채팅상담",
    counseldAt: "2024.10.20",
    title: "선생님 감사합니다2.",
    description:
      "선생님~안녕하세요 선생님과의\n 첫번째상담이었어용!\n 상대방 속마음과 생각들을 너무 정확하게\n 들을 수 있는 시간이었습니다. \n 선생님의 가장 뛰어나신 부분은 성향을 정확하게 \n 파악하시고 그거에 맞는 답변을 콕 찝어서 해주시는 것 같았어요 정말 최고완전최고완전최고완전최고완전최고완전\n 아 진짜 정말",
    teacher: {
      id: 2,
      name: "심채운2",
      status: "상담중",
      rating: "4.2",
      price: "1,500",
      tags: ["연애"],
      isNew: true,
      categories: ["#단호박 공수", "#직설적인"],
      img: "https://images.unsplash.com/photo-1731020202106-ba0ff3ef8cae?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHx0b3BpYy1mZWVkfDh8TThqVmJMYlRSd3N8fGVufDB8fHx8fA%3D%3D",
      kind: "신점",
      like: false,
    },
  },

  {
    userNickName: "두가두가3",
    userProfileImg:
      "https://images.unsplash.com/photo-1714250175930-b125907e44e6?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHx0b3BpYy1mZWVkfDh8RS0tX3BuSWlyRzR8fGVufDB8fHx8fA%3D%3D",
    counselKind: "전화상담",
    counseldAt: "2024.10.23",
    title: "선생님 감사합니다3.",
    description:
      "선생님~안녕하세요 선생님과의\n 첫번째상담이었어용!\n 상대방 속마음과 생각들을 너무 정확하게\n 들을 수 있는 시간이었습니다. \n 선생님의 가장 뛰어나신 부분은 성향을 정확하게 \n 파악하시고 그거에 맞는 답변을 콕 찝어서 해주시는 것 같았어요 정말 최고완전최고완전최고완전최고완전최고완전\n 아 진짜 정말",
    teacher: {
      id: 1,
      name: "심채운3",
      status: "상담중",
      rating: "4.2",
      price: "1,500",
      tags: ["연애"],
      isNew: true,
      categories: ["#단호박 공수", "#직설적인"],
      img: "https://images.unsplash.com/photo-1713118775710-2b3b2e8e74f7?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHx0b3BpYy1mZWVkfDd8RS0tX3BuSWlyRzR8fGVufDB8fHx8fA%3D%3D",
      kind: "신점",
      like: false,
    },
  },
  {
    userNickName: "두가두가4",
    userProfileImg:
      "https://images.unsplash.com/photo-1702590446331-64376aab81cd?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHx0b3BpYy1mZWVkfDE5fEUtLV9wbklpckc0fHxlbnwwfHx8fHw%3D",
    counselKind: "전화상담",
    counseldAt: "2024.10.24",
    title: "선생님 감사합니다4.",
    description:
      "선생님~안녕하세요 선생님과의\n 첫번째상담이었어용!\n 상대방 속마음과 생각들을 너무 정확하게\n 들을 수 있는 시간이었습니다. \n 선생님의 가장 뛰어나신 부분은 성향을 정확하게 \n 파악하시고 그거에 맞는 답변을 콕 찝어서 해주시는 것 같았어요 정말 최고완전최고완전최고완전최고완전최고완전\n 아 진짜 정말",
    teacher: {
      id: 1,
      name: "심채운4",
      status: "상담중",
      rating: "4.2",
      price: "1,500",
      tags: ["연애"],
      isNew: true,
      categories: ["#단호박 공수", "#직설적인"],
      img: "https://images.unsplash.com/photo-1731100062072-3904a79ff817?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHx0b3BpYy1mZWVkfDR8TThqVmJMYlRSd3N8fGVufDB8fHx8fA%3D%3D",
      kind: "신점",
      like: false,
    },
  },
  {
    userNickName: "두가두가5",
    userProfileImg:
      "https://images.unsplash.com/photo-1720982018832-35b144516bb9?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHx0b3BpYy1mZWVkfDN8RS0tX3BuSWlyRzR8fGVufDB8fHx8fA%3D%3D",
    counselKind: "전화상담",
    counseldAt: "2024.10.05",
    title: "선생님 감사합니다5.",
    description:
      "선생님~안녕하세요 선생님과의\n 첫번째상담이었어용!\n 상대방 속마음과 생각들을 너무 정확하게\n 들을 수 있는 시간이었습니다. \n 선생님의 가장 뛰어나신 부분은 성향을 정확하게 \n 파악하시고 그거에 맞는 답변을 콕 찝어서 해주시는 것 같았어요 정말 최고완전최고완전최고완전최고완전최고완전\n 아 진짜 정말",
    teacher: {
      id: 1,
      name: "심채운5",
      status: "상담중",
      rating: "4.2",
      price: "1,500",
      tags: ["연애"],
      isNew: true,
      categories: ["#단호박 공수", "#직설적인"],
      img: "https://images.unsplash.com/photo-1731100062072-3904a79ff817?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHx0b3BpYy1mZWVkfDR8TThqVmJMYlRSd3N8fGVufDB8fHx8fA%3D%3D",
      kind: "신점",
      like: false,
    },
  },
];

export const POPULARTEACHER: TeacherCardProps[] = [
  {
    id: 1,
    name: "심채운",
    status: "상담중",
    rating: "4.2",
    price: "1,500",
    tags: ["연애"],
    isNew: true,
    categories: ["#단호박 공수", "#직설적인"],
    img: "https://images.unsplash.com/photo-1731100062072-3904a79ff817?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHx0b3BpYy1mZWVkfDR8TThqVmJMYlRSd3N8fGVufDB8fHx8fA%3D%3D",
    kind: "신점",
    like: false,
  },
  {
    id: 2,
    name: "심채운1",
    status: "상담가능",
    rating: " 5.8",
    price: "1,450",
    tags: ["이별", "속마음"],
    isNew: true,
    categories: ["#단호박 공수", "#직설적인"],
    img: "https://images.unsplash.com/photo-1731020202106-ba0ff3ef8cae?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHx0b3BpYy1mZWVkfDh8TThqVmJMYlRSd3N8fGVufDB8fHx8fA%3D%3D",
    kind: "사주",
    like: true,
  },
  {
    id: 3,
    name: "심채운2",
    status: "부재중",
    rating: "5.0",
    price: "1,200",
    tags: ["이별", "다이어트", "부동산"],
    isNew: true,
    categories: ["#단호박 공수", "#직설적인"],
    img: "https://images.unsplash.com/photo-1731009918561-3f8b6fbc0e63?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHx0b3BpYy1mZWVkfDExfE04alZiTGJUUndzfHxlbnwwfHx8fHw%3D",
    kind: "사주",
    like: false,
  },
  {
    id: 4,
    name: "심채운3",
    status: "상담중",
    rating: "5.0",
    price: "900",
    tags: ["연애", "직장", "직장", "재회"],
    isNew: true,
    categories: ["#단호박 공수", "#직설적인"],
    img: "https://images.unsplash.com/photo-1730888515663-5058024f2e60?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHx0b3BpYy1mZWVkfDE4fE04alZiTGJUUndzfHxlbnwwfHx8fHw%3D",
    kind: "신점",
    like: true,
  },
];

export const SEARCHTEACHER: TeacherCardProps[] = [
  {
    id: 1,
    name: "심채운",
    status: "상담가능",
    rating: "4.2",
    price: "1,500",
    tags: ["연애"],
    isNew: true,
    categories: ["#단호박 공수", "#직설적인"],
    img: "https://images.unsplash.com/photo-1731100062072-3904a79ff817?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHx0b3BpYy1mZWVkfDR8TThqVmJMYlRSd3N8fGVufDB8fHx8fA%3D%3D",
    kind: "신점",
    like: false,
  },
  {
    id: 2,
    name: "심채2",
    status: "상담가능",
    rating: "4.2",
    price: "1,500",
    tags: ["연애"],
    isNew: true,
    categories: ["#단호박 공수", "#직설적인"],
    img: "https://images.unsplash.com/photo-1731100062072-3904a79ff817?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHx0b3BpYy1mZWVkfDR8TThqVmJMYlRSd3N8fGVufDB8fHx8fA%3D%3D",
    kind: "신점",
    like: false,
  },
];
