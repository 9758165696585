import SigninForm from "@organisms/Form/Sign/SigninForm";
import { LoginValue } from "@models";

function SignInLayout() {
  const onSubmit = (data: LoginValue) => {
    console.log(data);
  };
  return <SigninForm onSubmit={onSubmit} />;
}

export default SignInLayout;
