import * as S from "./Heart.style";
import Icon from "@atoms/Icon/Icon";

interface HeartProps {
  onClick: () => void;
  like: boolean;
  top?: string;
  left?: string;
  right?: string;
  bottom?: string;
  size: "large" | "small";
}

const Heart = ({
  onClick,
  like,
  top,
  left,
  right,
  bottom,
  size,
}: HeartProps) => {
  return (
    <S.HeartContainer
      onClick={onClick}
      top={top}
      left={left}
      right={right}
      bottom={bottom}
      size={size}
    >
      <Icon
        name={like ? "IconFillHeartTeacher" : "IconHeartTeacher"}
        style={{
          cursor: "pointer",
        }}
        width={size === "large" ? "5rem" : "3.8rem"}
        height={size === "large" ? "4.1rem" : "3.8rem"}
      />
    </S.HeartContainer>
  );
};

export default Heart;
