import { colors } from "@/style/colorPalette";
import styled from "@emotion/styled";

export const SearchDropDownContainer = styled.ul`
  width: 30rem;
  /* height: 300px; */
  border-radius: 2rem;
  background-color: ${colors.white100};
  position: absolute;
  /* right: -120px; */
  /* top: 62px; */
  top: 3rem;
  /* right: -12rem; */
  z-index: 2;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  .item {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    border-bottom: 1px solid ${colors.grey85};
    padding: 1rem 0 1rem 1.5rem;
    cursor: pointer;
    transition: all 0.1s;

    &:hover {
      transform: scale(1.015);
    }

    :last-child {
      border-bottom: 0px;
    }
  }
`;
