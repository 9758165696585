import React from "react";
import { CSSProperties } from "react";

import * as S from "./Label.style";
import { ColorsType } from "@style/colorPalette";
import { TypographyType } from "@style/typography";

export interface LabelProps {
  htmlFor?: string;
  typography?: TypographyType;
  display?: CSSProperties["display"];
  bold?: boolean;
  color?: ColorsType;
  fontWeight?: CSSProperties["fontWeight"];
  children: React.ReactNode;
}

const Label = ({
  htmlFor,
  typography,
  display,
  bold,
  color,
  fontWeight,
  children,
}: LabelProps) => {
  return (
    <S.Label
      htmlFor={htmlFor}
      typography={typography}
      display={display}
      bold={bold}
      color={color}
      fontWeight={fontWeight}
    >
      {children}
    </S.Label>
  );
};

export default Label;
