import * as S from "./Grid.style";

export interface GridProps {
  columns?: number;
  columnGap?: number;
  rowGap?: number;
  children: React.ReactNode;
}

function Grid({ children, ...props }: GridProps) {
  return <S.Grid {...props}>{children}</S.Grid>;
}

export default Grid;
