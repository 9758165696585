import * as S from "./MainLayout.style";
import MainBanner from "@organisms/Banner/MainBanner";
import TopOnePercentTeacherSection from "@organisms/Section/TopOnePercentTeacherSection";
import TopTenTeacherSection from "@organisms/Section/TopTenTeacherSection";
import EventBanner from "@organisms/Banner/EventBanner";
import CanCounselTeacherSection from "@organisms/Section/CanCounselTeacherSection";
import BestReviewSection from "@organisms/Section/BestReviewSection";
import PopularTeacherSection from "@organisms/Section/PopularTeacherSection";
import Spacer from "@atoms/Spacer/Spacer";

function MainLayout() {
  return (
    <S.MainLayout>
      <MainBanner />
      <Spacer space="2.2rem" />
      <TopOnePercentTeacherSection />
      <Spacer space="4rem" />
      <TopTenTeacherSection />
      <Spacer space="2.7rem" />
      <EventBanner />
      <Spacer space="4rem" />
      <CanCounselTeacherSection />
      <Spacer space="3.6rem" />
      <BestReviewSection />
      <Spacer space="2.2rem" />
      <PopularTeacherSection />
    </S.MainLayout>
  );
}

export default MainLayout;
