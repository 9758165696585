import { colors } from "@/style/colorPalette";
import styled from "@emotion/styled";

export const CustomCheckBoxContainer = styled.div<{
  width?: string;
  height?: string;
  borderRadius: string;
  padding: string;
  hasBorder?: boolean;
}>`
  width: ${({ width = "70.6rem" }) => width};
  height: ${({ height = "7.8rem" }) => height};
  border-radius: ${({ borderRadius }) => borderRadius};
  border: ${({ hasBorder }) =>
    hasBorder ? `1px solid ${colors.grey77}` : "none"};
  box-sizing: border-box;
  padding: 2.3rem 2.4rem;
  padding: ${({ padding }) => padding};
  transition: all 0.3s ease;

  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    border-color: ${colors.main};
  }
`;
