import React from "react";
import * as S from "./CategoryBox.style";

export interface CategoryBoxProps {
  children: React.ReactNode;
  size?: "small" | "large";
}

function CategoryBox({ children, size = "large" }: CategoryBoxProps) {
  return <S.CategoryBox size={size}>{children}</S.CategoryBox>;
}

export default CategoryBox;
