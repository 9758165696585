import { MEDIA_QUERIES } from "@/constants/breakpoint";
import styled from "@emotion/styled";
import { colors } from "@style/colorPalette";

export const MainBannerContainer = styled.div`
  max-width: 1121px;
  margin: 0 auto;
  display: flex;
  width: 100%;
  height: 324px;
  position: relative;
  border-radius: 2rem;
  overflow: hidden;

  @media ${MEDIA_QUERIES.MOBILE} {
    height: 100px;
    max-width: 300px;
  }

  @media ${MEDIA_QUERIES.TABLET} {
    max-width: 550px;
    height: 180px;
  }

  @media ${MEDIA_QUERIES.LAPTOP} {
    max-width: 900px;
    height: 300px;
  }

  .swiper-pagination {
    width: 40px;
    height: 24px;
    background-color: ${colors.grey93};
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${colors.white100};
    border-radius: 5px;
    font-size: 14px;
    gap: 3px;

    .swiper-pagination-current {
      font-weight: bold;
    }

    @media ${MEDIA_QUERIES.MOBILE} {
      width: 35px;
      height: 20px;
      font-size: 11px;
    }
  }

  .swiper-pagination-fraction {
    top: auto;
    left: auto;
    bottom: 13px;
    right: 27px;

    @media ${MEDIA_QUERIES.MOBILE} {
      bottom: 8px;
      right: 15px;
    }
  }
`;
