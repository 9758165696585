import styled from "@emotion/styled";

import { MEDIA_QUERIES } from "@constants/breakpoint";
import { colors } from "@style/colorPalette";

export const TeacherCardContainer = styled.div<{ size: "small" | "large" }>`
  height: ${({ size }) => (size === "large" ? " 480px" : "276px")};
  max-width: 438px;
  width: 100%;
  border: 2px solid ${colors.grey90};
  border-radius: 2.4rem;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 16px 16px -8px rgba(0, 0, 0, 0.5);
  }

  @media ${MEDIA_QUERIES.MOBILE} {
    height: ${({ size }) => (size === "large" ? "265px" : "320px")};
    border: 1px solid ${colors.grey90};
  }
  @media ${MEDIA_QUERIES.TABLET} {
    height: ${({ size }) => (size === "large" ? "320px" : "320px")};
    border: 1px solid ${colors.grey90};
  }
  @media ${MEDIA_QUERIES.LAPTOP} {
    height: ${({ size }) => (size === "large" ? "390px" : "266px")};
  }
`;

export const TeacherBlackBg = styled.div<{ size: "small" | "large" }>`
  position: absolute;
  width: 100%;
  height: ${({ size }) => (size === "large" ? "341px" : "205px")};
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${MEDIA_QUERIES.MOBILE} {
    height: ${({ size }) => (size === "large" ? "161px" : "320px")};
  }
  @media ${MEDIA_QUERIES.TABLET} {
    height: ${({ size }) => (size === "large" ? "201px" : "205px")};
  }
  @media ${MEDIA_QUERIES.LAPTOP} {
    height: ${({ size }) => (size === "large" ? "271px" : "205px")};
  }
`;
