import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { ButtonProps } from "./Button";
import { typographyMap } from "@style/typography";
import { buttonColorMap, buttonWeakMap } from "@style/button";

export const Button = styled.button<ButtonProps>`
  cursor: pointer;
  transition: all 0.5s;

  width: ${({ width = "10rem" }) => width};
  height: ${({ height = "4rem" }) => height};

  font-weight: ${({ fontWeight = 400 }) => fontWeight};
  border-radius: ${({ borderRadius = "1rem" }) => borderRadius};

  ${({ color = "primary", weak }) =>
    weak ? buttonWeakMap[color] : buttonColorMap[color]};

  ${({ typography = "t16" }) => typographyMap[typography]};

  ${({ full }) =>
    full &&
    `
      display: block;
      width: 100%;
      border-radius: 0;
    `};

  ${({ disabled }) =>
    disabled &&
    `
      opacity: 0.26;
      cursor: default;
    `};

  ${({ hover = true, color = "primary", weak }) =>
    hover &&
    css`
      &:hover {
        ${weak ? buttonColorMap[color] : buttonWeakMap[color]}
      }
    `}
`;
