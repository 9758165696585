import { useNavigate } from "react-router-dom";

import { colors } from "@style/colorPalette";
import * as S from "./HeaderMenu.style";
import Flex from "@atoms/Flex/Flex";
import Text from "@atoms/Text/Text";
import TextArrowGroup from "@molecules/Group/TextArrowGroup";
import InputGroup from "@molecules/Group/InputGroup";

interface HeaderMenuProps {
  onChange: (value: string) => void;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  inputValue: string;
  clickIcon: () => void;
}

const HeaderMenu = ({
  onChange,
  onSubmit,
  inputValue,
  clickIcon,
}: HeaderMenuProps) => {
  const navigate = useNavigate();
  return (
    <S.HeaderMenuContainer direction="column" justify="space-around">
      <Flex
        align="center"
        gap={2}
        justify="flex-end"
        style={{
          marginRight: "27px",
        }}
      >
        <Text
          typography="t12"
          color="blue56"
          fontWeight={600}
          style={{
            cursor: "pointer",
          }}
        >
          코인충전
        </Text>

        <Text
          typography="t12"
          color="blue56"
          fontWeight={600}
          style={{
            cursor: "pointer",
          }}
        >
          고객센터
        </Text>

        <TextArrowGroup
          title="마이메뉴"
          iconName="IconHeaderCategoryRightArrow"
          typography="t12"
          iconWidth="0.7rem"
          iconHeight="0.5rem"
          onClick={() => navigate("/login")}
        />
      </Flex>

      <InputGroup
        onChange={onChange}
        onSubmit={onSubmit}
        inputValue={inputValue}
        clickIcon={clickIcon}
        iconName="IconHeaderSearch"
        placeHolder="상담마스터 검색하기"
        iconBoxWidth="34px"
        iconBoxHeight="34px"
        iconBoxBg={colors.main}
        width="27.9rem"
      />
    </S.HeaderMenuContainer>
  );
};

export default HeaderMenu;
