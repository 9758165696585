import styled from "@emotion/styled";
import { ImageProps } from "@atoms/Image/Image";

export const Image = styled.img<ImageProps>`
  width: ${({ width }) => width || "auto"};
  height: ${({ height }) => height || "auto"};
  border-radius: ${({ borderRadius }) => borderRadius || "0"};
  transition: all 0.3s;
  object-fit: ${({ objectFit = "cover" }) => objectFit};
`;
