import { useState } from "react";
import Icon, { IconNameType } from "@atoms/Icon/Icon";

function Left({ disabled }: { disabled: boolean }) {
  const [iconLeft, setIconLeft] = useState<IconNameType>("IconCircleArrowLeft");
  return (
    <Icon
      name={iconLeft}
      width="3.9rem"
      height="3.9rem"
      onMouseEnter={() => {
        if (disabled) {
          return;
        } else {
          setIconLeft("IconCircleArrowLeftHover");
        }
      }}
      onMouseLeave={() => setIconLeft("IconCircleArrowLeft")}
    />
  );
}

export default Left;
