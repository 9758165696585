import styled from "@emotion/styled";

import { MEDIA_QUERIES } from "@constants/breakpoint";
import { colors } from "@style/colorPalette";

export const BestReviewCardContainer = styled.div`
  width: 38.1rem;
  height: 46.9rem;
  border-radius: 20px;
  background-color: ${colors.white100};
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 3.1rem 2.2rem 3.2rem 1.3rem;
  box-sizing: border-box;
  cursor: pointer;

  @media ${MEDIA_QUERIES.MOBILE} {
  }
  @media ${MEDIA_QUERIES.TABLET} {
  }
  @media ${MEDIA_QUERIES.LAPTOP} {
    /* height: 40rem; */
  }

  .card {
    width: 100%;
    height: 9.4rem;
    border: 1px solid ${colors.grey93};
    border-radius: 1rem;
    display: flex;
    align-items: center;
    padding: 1.3rem 1.4rem;
    box-sizing: border-box;
  }
`;
