import styled from "@emotion/styled";

import Icon from "@atoms/Icon/Icon";
import { MEDIA_QUERIES } from "@constants/breakpoint";
import { colors } from "@style/colorPalette";

export const FindResultContainer = styled.div`
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
  padding: 2.4rem 3rem 5rem 3rem;
  box-sizing: border-box;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-color: pink; */

  @media ${MEDIA_QUERIES.MOBILE} {
    max-width: 300px;
  }
  @media ${MEDIA_QUERIES.TABLET} {
    max-width: 400px;
  }
  @media ${MEDIA_QUERIES.LAPTOP} {
    max-width: 500px;
  }
`;

export const StyledIcon = styled(Icon)`
  position: absolute;
  left: 2.8rem;
  cursor: pointer;
  transform: scale(1);
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.08);
  }
`;

export const UserInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 2.9rem;
  width: 100%;

  height: 14rem;

  padding: 3.8rem 0rem 3.8rem 4rem;
  box-sizing: border-box;
  background-color: ${colors.ivory95};
  border-radius: 1.5rem;
`;
