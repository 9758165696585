import styled from "@emotion/styled";

import { colors } from "@style/colorPalette";

export const Bg = styled.div`
  width: 100%;
`;

export const ProgressWrapper = styled.div`
  width: 100%;
  height: 0.8rem;
  background-color: #e0e0e0;
  overflow: hidden;
  margin-bottom: 2rem;
  position: sticky;
  top: 0;
`;

export const ProgressBar = styled.div<{ width: string }>`
  height: 100%;
  width: ${(props) => props.width};
  background-color: ${colors.main};
  transition: width 0.3s ease;
`;
