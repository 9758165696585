import styled from "@emotion/styled";
import { InputProps } from "@atoms/Input/Input";
import { colors } from "@style/colorPalette";
import { typographyMap } from "@style/typography";

export const Input = styled.input<InputProps>`
  padding: ${({ padding = "0 1.4rem" }) => padding};
  ${({ typography = "t22" }) => typographyMap[typography]};
  height: ${({ height = "6.3rem" }) => height};
  font-weight: 400;
  border: 1px solid ${colors.grey77};
  border-radius: ${({ borderRadius = "0.6rem" }) => borderRadius};
  width: 100%;
  box-sizing: border-box;
  color: ${({ color = "black" }) => colors[color]};

  &::placeholder {
    color: ${({ placeholderColor }) => placeholderColor || colors.grey67};
    font-size: ${({ placeholderFontSize = "t22" }) => placeholderFontSize};
  }

  &:hover {
    border-color: ${colors.black};
  }

  &:focus {
    outline: none;
    border-color: ${colors.black};
  }

  &[aria-invalid="true"] {
    border-color: ${colors.red50};
  }
`;
