import styled from "@emotion/styled";
import { GridProps } from "@atoms/Grid/Grid";

export const Grid = styled.div<GridProps>`
  display: grid;
  grid-template-columns: repeat(
    ${(props) => props.columns || 2},
    1fr
  ); // 기본 열 2개
  column-gap: ${(props) => props.columnGap || 1.6}rem;
  row-gap: ${(props) => props.rowGap || 1.6}rem;
  place-items: center;
`;
