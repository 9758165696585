import { ColorsType } from "@style/colorPalette";

export const returnColor = (kind: "신점" | "사주" | "타로"): ColorsType => {
  if (kind === "신점") {
    return "red50";
  } else if (kind === "타로") {
    return "blue56";
  } else {
    return "brown33";
  }
};

export const formatDateForKorean = (dateString: string) => {
  const [year, month, day] = dateString.split("-");
  return `${year}년 ${month}월 ${day}일`;
};
