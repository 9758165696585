import { colors, ColorsType } from "@style/colorPalette";
import styled from "@emotion/styled";

export const FormContainer = styled.form`
  /* max-width: 600px; */
  max-width: 600px;
  width: 100%;
  padding: 0 3rem;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const SocialLoginBtn = styled.div<{ color: ColorsType }>`
  max-width: 600px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 8rem;
  position: relative;
  background-color: ${({ color }) => colors[color]};
  border-radius: 1.5rem;
  cursor: pointer;

  svg {
    position: absolute;
    left: 3rem;
  }
`;
