import styled from "@emotion/styled";
import { MEDIA_QUERIES } from "@constants/breakpoint";

export const SectionContainer = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  max-width: 900px;

  @media ${MEDIA_QUERIES.MOBILE} {
    /* max-width: 900px; */
    max-width: 250px;
  }
  @media ${MEDIA_QUERIES.TABLET} {
    max-width: 480px;
  }
  @media ${MEDIA_QUERIES.LAPTOP} {
    max-width: 750px;
  }
`;
